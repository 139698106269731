import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { FaLock } from 'react-icons/fa';

const CybersecurityIncidents = () => {
  // Fake data for Cybersecurity Incidents
  const cybersecurityData = [
    { month: "Jan", incidents: 5 },
    { month: "Feb", incidents: 2 },
    { month: "Mar", incidents: 4 },
    { month: "Apr", incidents: 7 },
    { month: "May", incidents: 1 },
    { month: "Jun", incidents: 3 },
    { month: "Jul", incidents: 6 },
    { month: "Aug", incidents: 8 },
    { month: "Sep", incidents: 4 },
    { month: "Oct", incidents: 5 },
    { month: "Nov", incidents: 2 },
    { month: "Dec", incidents: 3 },
  ];

  // Prepare data for Cybersecurity Incidents chart
  const cybersecurityChartData = {
    series: [
      {
        name: 'Incidents',
        data: cybersecurityData.map(item => item.incidents),
      },
    ],
    categories: cybersecurityData.map(item => item.month),
  };

  // Chart options for Cybersecurity
  const cybersecurityOptions = {
    chart: {
      type: 'bar',
      height: '100%',
      zoom: {
        enabled: false,
      },
    },
    colors: ['#FF4560'],
    xaxis: {
      categories: cybersecurityChartData.categories,
      title: {
        text: 'Month',
        style: {
          color: '#FFFFFF',
        },
      },
      labels: {
        style: {
          colors: '#FFFFFF', // Ensure x-axis labels are white
        },
      },
    },
    yaxis: {
      title: {
        text: 'Number of Incidents',
        style: {
          color: '#FFFFFF',
        },
      },
      min: 0,
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      floating: true,
      offsetY: 0,
      labels: {
        colors: '#FFFFFF',
      },
    },
    // Add padding to the top of the chart
    grid: {
      padding: {
        top: 10, // Reduce the padding value to move the chart higher
      },
    },
  };

  return (
    <div className="bg-gray-800 rounded-xl p-4 h-full mb-4">
      <h2 className="text-xl font-extrabold mb-2 flex items-center text-white">
        <FaLock className="mr-2 text-blue-300" /> Cybersecurity Incidents
      </h2>
      <div className="h-60"> {/* Adjusted height for better fit */}
        <ReactApexChart options={cybersecurityOptions} series={cybersecurityChartData.series} type="bar" height="100%" />
      </div>
    </div>
  );
};

export default CybersecurityIncidents;
