import React from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faClipboardCheck, faUserCheck, faUserTie } from '@fortawesome/free-solid-svg-icons';

const MissionsOverview = () => {
  // Fake data for demonstration
  const totalMissionsAssigned = 100;
  const totalMissionsResolvedByAgents = 70;
  const totalMissionsResolvedByOperator = 30;

  // Data for the pie chart
  const missionDistributionData = {
    labels: ['Resolved by Agents', 'Resolved by Operator'],
    datasets: [{
      data: [totalMissionsResolvedByAgents, totalMissionsResolvedByOperator],
      backgroundColor: ['rgba(54, 162, 235, 0.8)', 'rgba(255, 206, 86, 0.8)'],
      borderWidth: 1,
    }],
  };

  // Data for the bar chart (missions assigned over time)
  const missionsOverTimeData = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
    datasets: [{
      label: 'Total Missions Assigned',
      data: [20, 30, 25, 25],
      backgroundColor: 'rgba(255, 99, 132, 0.8)',
    }],
  };

  // Recent missions data for the table
  const recentMissions = [
    { id: 1, name: 'Mission Alpha', status: 'Resolved by Agent', date: '2024-10-20', duration: '2 hrs', priority: 'High' },
    { id: 2, name: 'Mission Beta', status: 'Resolved by Operator', date: '2024-10-19', duration: '3 hrs', priority: 'Medium' },
    { id: 3, name: 'Mission Gamma', status: 'Resolved by Agent', date: '2024-10-18', duration: '1.5 hrs', priority: 'Low' },
    { id: 4, name: 'Mission Delta', status: 'Resolved by Agent', date: '2024-10-17', duration: '4 hrs', priority: 'High' },
    { id: 5, name: 'Mission Epsilon', status: 'Resolved by Operator', date: '2024-10-16', duration: '2.5 hrs', priority: 'Medium' },
  ];

  return (
    <div className="bg-gray-800 p-4 rounded-lg shadow-lg mt-6">
      <h2 className="text-xl text-white font-bold mb-4 flex items-center">
        <FontAwesomeIcon icon={faCheckSquare} className="mr-2" />
        Missions Overview
      </h2>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
        <div className="flex flex-col items-center bg-blue-900 p-4 rounded-lg shadow-md">
          <FontAwesomeIcon icon={faClipboardCheck} className="text-3xl text-green-400 mb-2" />
          <h3 className="text-lg text-white">Total Missions Assigned</h3>
          <p className="text-3xl text-white font-bold">{totalMissionsAssigned}</p>
        </div>
        <div className="flex flex-col items-center bg-green-900 p-4 rounded-lg shadow-md">
          <FontAwesomeIcon icon={faUserCheck} className="text-3xl text-yellow-400 mb-2" />
          <h3 className="text-lg text-white">Missions Resolved by Agents</h3>
          <p className="text-3xl text-white font-bold">{totalMissionsResolvedByAgents}</p>
        </div>
        <div className="flex flex-col items-center bg-orange-900 p-4 rounded-lg shadow-md">
          <FontAwesomeIcon icon={faUserTie} className="text-3xl text-blue-400 mb-2" />
          <h3 className="text-lg text-white">Missions Resolved by Operator</h3>
          <p className="text-3xl text-white font-bold">{totalMissionsResolvedByOperator}</p>
        </div>
      </div>

      <h3 className="text-lg text-white mb-2">Missions Resolved Distribution</h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        <div className="col-span-1 h-48">
          <Pie data={missionDistributionData} options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'bottom',
                labels: {
                  color: 'white',
                },
              },
            },
          }} height={150} />
        </div>
        <div className="col-span-1 h-48">
          <Bar data={missionsOverTimeData} options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
                grid: {
                  color: 'rgba(255, 255, 255, 0.1)',
                },
                ticks: {
                  color: 'white',
                },
              },
              x: {
                grid: {
                  color: 'rgba(255, 255, 255, 0.1)',
                },
                ticks: {
                  color: 'white',
                },
              },
            },
            plugins: {
              legend: {
                labels: {
                  color: 'white',
                },
              },
            },
          }} height={150} />
        </div>
        <div className="col-span-1 h-48 overflow-auto">
  <h3 className="text-lg text-white mb-2">Recent Missions</h3>
  <table className="min-w-full bg-gray-700 text-white rounded-lg">
    <thead>
      <tr className="bg-gray-600 text-sm font-semibold">
        <th className="py-2 px-2">Mission Name</th>
        <th className="py-2 px-2">Status</th>
        <th className="py-2 px-2">Date</th>
        <th className="py-2 px-2">Duration</th>
        <th className="py-2 px-2">Priority</th>
      </tr>
    </thead>
    <tbody className="text-sm">
      {recentMissions.map(mission => (
        <tr key={mission.id} className="border-b border-gray-500 hover:bg-gray-600 transition-colors duration-300">
          <td className="py-1 px-2">{mission.name}</td>
          <td className={`py-1 px-2 ${mission.status.includes('Agent') ? 'text-green-400' : 'text-yellow-400'}`}>{mission.status}</td>
          <td className="py-1 px-2">{mission.date}</td>
          <td className="py-1 px-2">{mission.duration}</td>
          <td className={`py-1 px-2 ${mission.priority === 'High' ? 'text-red-400' : mission.priority === 'Medium' ? 'text-yellow-400' : 'text-green-400'}`}>{mission.priority}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

      </div>
    </div>
  );
};

export default MissionsOverview;
