import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { FaParking } from 'react-icons/fa';

const ParkingSpaceAlerts = () => {
  // Fake data for Parking Space Alerts
  const parkingData = [
    { month: "Jan", alerts: 2 },
    { month: "Feb", alerts: 3 },
    { month: "Mar", alerts: 4 },
    { month: "Apr", alerts: 5 },
    { month: "May", alerts: 7 },
    { month: "Jun", alerts: 6 },
    { month: "Jul", alerts: 8 },
    { month: "Aug", alerts: 5 },
    { month: "Sep", alerts: 4 },
    { month: "Oct", alerts: 2 },
    { month: "Nov", alerts: 3 },
    { month: "Dec", alerts: 5 },
  ];

  // Current available parking spaces (fake data)
  const totalSpaces = 50;
  const usedSpaces = 30;
  const availableSpaces = totalSpaces - usedSpaces;

  // Prepare data for the Parking Space Alerts chart
  const parkingChartData = {
    series: [
      {
        name: 'Alerts',
        data: parkingData.map(item => item.alerts),
      },
    ],
    categories: parkingData.map(item => item.month),
  };

  // Chart options for Parking Space Alerts
  const parkingOptions = {
    chart: {
      type: 'bar',
      height: '100%',
      zoom: {
        enabled: false,
      },
    },
    colors: ['#FFB300'],
    xaxis: {
      categories: parkingChartData.categories,
      title: {
        text: 'Month',
        style: {
          color: '#FFFFFF',
        },
      },
      labels: {
        style: {
          colors: '#FFFFFF',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Number of Alerts',
        style: {
          color: '#FFFFFF',
        },
      },
      min: 0,
      max: Math.max(...parkingData.map(item => item.alerts)) + 2,
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      floating: true,
      offsetY: 0,
      labels: {
        colors: '#FFFFFF',
      },
    },
    grid: {
      padding: {
        top: 10,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#FFFFFF'],
      },
    },
  };

  return (
    <div className="bg-gray-800 rounded-xl p-4 h-64 mb-4">
      <h2 className="text-xl font-extrabold mb-2 flex items-center text-white">
        <FaParking className="mr-2 text-blue-300" /> Parking Space Alerts
      </h2>
      <div className="h-48"> {/* Adjusted height for the chart */}
        <ReactApexChart options={parkingOptions} series={parkingChartData.series} type="bar" height="100%" />
      </div>
      <div className="flex justify-between text-white mb-4">
        <p>Available Spaces: <span className="font-bold text-green-400">{availableSpaces}</span></p>
        <p>Used Spaces: <span className="font-bold text-red-400">{usedSpaces}</span></p>
      </div>
    </div>
  );
};

export default ParkingSpaceAlerts;
