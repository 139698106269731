import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const severityIcons = {
  critical: faExclamationTriangle,
  major: faExclamationCircle,
  minor: faCheckCircle,
};

// Function to determine the color based on severity
const getSeverityColor = (severity) => {
  switch (severity) {
    case 'critical':
      return 'bg-red-500';
    case 'major':
      return 'bg-yellow-500';
    case 'minor':
      return 'bg-green-500';
    default:
      return 'bg-gray-500';
  }
};

const StatusTable = ({ data }) => {
  if (!data || !data.systemFailures) {
    return <div className="text-white text-center">Loading...</div>; // Loading state
  }

  return (
    <div className="overflow-x-auto bg-transparent">
      <table className="min-w-full bg-gray-800 text-left text-sm text-gray-300"> {/* Updated background color */}
        <thead className="bg-gray-700 text-white">
          <tr>
            <th className="px-4 py-2">System/Device</th>
            <th className="px-4 py-2">Status</th>
            <th className="px-4 py-2">Last Update</th>
            <th className="px-4 py-2">Severity</th>
          </tr>
        </thead>
        <tbody>
          {/* System Failures */}
          {data.systemFailures && data.systemFailures.map((system) => (
            <tr key={system.systemId} className="border-b border-gray-600">
              <td className="px-4 py-2">{system.systemName}</td>
              <td className="px-4 py-2">{system.status}</td>
              <td className="px-4 py-2">{new Date(system.lastUpdate).toLocaleString()}</td>
              <td className="px-4 py-2 flex items-center">
                <FontAwesomeIcon icon={severityIcons[system.severity]} className={`mr-2 ${getSeverityColor(system.severity)} p-1 rounded-full`} />
                {system.severity}
              </td>
            </tr>
          ))}
          {/* Device Failures */}
          {data.deviceFailures && data.deviceFailures.map((device) => (
            <tr key={device.deviceId} className="border-b border-gray-600">
              <td className="px-4 py-2">{device.deviceName}</td>
              <td className="px-4 py-2">{device.status}</td>
              <td className="px-4 py-2">{new Date(device.lastUpdate).toLocaleString()}</td>
              <td className="px-4 py-2 flex items-center">
                <FontAwesomeIcon icon={severityIcons[device.severity]} className={`mr-2 ${getSeverityColor(device.severity)} p-1 rounded-full`} />
                {device.severity}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StatusTable;
