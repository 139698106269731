import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { FaClock } from 'react-icons/fa';

const ParkingOverstaysAlerts = () => {
  // Fake data for Parking Overstays Alerts
  const overstaysData = [
    { month: "Jan", overstays: 1 },
    { month: "Feb", overstays: 4 },
    { month: "Mar", overstays: 3 },
    { month: "Apr", overstays: 6 },
    { month: "May", overstays: 2 },
    { month: "Jun", overstays: 5 },
    { month: "Jul", overstays: 7 },
    { month: "Aug", overstays: 3 },
    { month: "Sep", overstays: 4 },
    { month: "Oct", overstays: 5 },
    { month: "Nov", overstays: 2 },
    { month: "Dec", overstays: 6 },
  ];

  // Prepare data for the Parking Overstays Alerts chart
  const overstaysChartData = {
    series: [
      {
        name: 'Overstays',
        data: overstaysData.map(item => item.overstays),
      },
    ],
    categories: overstaysData.map(item => item.month),
  };

  // Chart options for Parking Overstays Alerts
  const overstaysOptions = {
    chart: {
      type: 'bar',
      height: '100%',
      zoom: {
        enabled: false,
      },
    },
    colors: ['#FF5733'],
    xaxis: {
      categories: overstaysChartData.categories,
      title: {
        text: 'Month',
        style: {
          color: '#FFFFFF',
        },
      },
      labels: {
        style: {
          colors: '#FFFFFF',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Number of Overstays',
        style: {
          color: '#FFFFFF',
        },
      },
      min: 0,
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      floating: true,
      offsetY: 0,
      labels: {
        colors: '#FFFFFF',
      },
    },
    grid: {
      padding: {
        top: 10,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#FFFFFF'],
      },
    },
  };

  return (
    <div className="bg-gray-800 rounded-xl p-4 h-64 mb-4">
      <h2 className="text-xl font-extrabold mb-2 flex items-center text-white">
        <FaClock className="mr-2 text-blue-300" /> Parking Overstays Alerts
      </h2>
      <div className="h-60"> {/* Adjusted height for the chart */}
        <ReactApexChart options={overstaysOptions} series={overstaysChartData.series} type="bar" height="100%" />
      </div>
    </div>
  );
};

export default ParkingOverstaysAlerts;
