import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register components for Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ data }) => {
  // Chart data
  const chartData = {
    labels: ["CCTV", "ACS", "IDS", "Fire", "Agents"],
    datasets: [
      {
        label: "System Alerts",
        data: [data.CCTV, data.ACS, data.IDS, data.Fire, data.Agents],
        backgroundColor: [
          'rgba(75, 192, 192, 0.8)',   // CCTV
          'rgba(255, 159, 64, 0.8)',   // ACS
          'rgba(153, 102, 255, 0.8)',  // IDS
          'rgba(255, 99, 132, 0.8)',   // Fire
          'rgba(54, 162, 235, 0.8)',   // Agents
        ],
        hoverBackgroundColor: [
          'rgba(75, 192, 192, 1)',   // CCTV on hover
          'rgba(255, 159, 64, 1)',   // ACS on hover
          'rgba(153, 102, 255, 1)',  // IDS on hover
          'rgba(255, 99, 132, 1)',   // Fire on hover
          'rgba(54, 162, 235, 1)',   // Agents on hover
        ],
        borderRadius: 20, // Highly rounded corners for a modern look
        borderSkipped: false, // No border on top
        barThickness: 50, // Thicker bars
        shadowOffsetX: 4, // Add subtle shadow
        shadowOffsetY: 4, // Shadow for modern depth
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Let the chart take up more space
    plugins: {
      legend: {
        display: false, // Remove legend for simplicity
      },
      tooltip: {
        enabled: true,
        backgroundColor: '#fff', // White tooltip background for modern look
        bodyColor: '#000', // Dark text for better contrast
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1,
        titleFont: { size: 14 },
        bodyFont: { size: 12 },
        boxPadding: 10,
        caretPadding: 8,
        padding: 10,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove X axis grid lines
        },
        ticks: {
          font: {
            size: 14,
            weight: 'bold',
          },
          color: '#ddd', // Lighter color for X-axis labels
        },
      },
      y: {
        grid: {
          display: false, // Remove Y axis grid lines
        },
        ticks: {
          display: false, // Hide Y axis labels for minimalism
        },
      },
    },
    animation: {
      duration: 1500, // Slow and smooth animation
      easing: 'easeInOutCubic', // Modern easing animation
    },
  };

  return (
    <div className="relative w-full h-96">
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default BarChart;
