import React from 'react';
import { FaNetworkWired, FaExclamationTriangle, FaClock, FaLocationArrow } from 'react-icons/fa';

const NetworkUptimeDowntimeCard = () => {
  // Fake data for Network Uptime and Downtime Alerts
  const data = [
    { month: "Jan", downtime: 2, alerts: 1, location: "Router A" },
    { month: "Feb", downtime: 5, alerts: 3, location: "Switch B" },
    { month: "Mar", downtime: 1, alerts: 2, location: "Router C" },
    { month: "Apr", downtime: 4, alerts: 4, location: "Router A" },
    { month: "May", downtime: 0, alerts: 0, location: "Switch D" },
    { month: "Jun", downtime: 6, alerts: 5, location: "Switch E" },
    { month: "Jul", downtime: 3, alerts: 2, location: "Router B" },
    { month: "Aug", downtime: 7, alerts: 6, location: "Router C" },
    { month: "Sep", downtime: 0, alerts: 0, location: "Router A" },
    { month: "Oct", downtime: 4, alerts: 3, location: "Switch D" },
    { month: "Nov", downtime: 1, alerts: 1, location: "Switch E" },
    { month: "Dec", downtime: 8, alerts: 7, location: "Router B" },
  ];

  // Sort and get top data
  const sortedData = data.sort((a, b) => b.downtime - a.downtime);
  const totalDowntime = sortedData.reduce((acc, item) => acc + item.downtime, 0);
  const topThree = sortedData.slice(0, 3);

  return (
    <div className="bg-gray-800 rounded-xl p-4 transition duration-300 ease-in-out transform">
      <h2 className="text-xl font-extrabold mb-4 flex items-center text-white">
        <FaNetworkWired className="mr-2 text-blue-300" /> Network Uptime & Downtime Alerts
      </h2>

      <div className="flex items-center mb-4">
        <FaExclamationTriangle className="text-yellow-400 mr-2" />
        <span className="text-white font-bold">
          Total Downtime: {totalDowntime} hours over {data.length} months
        </span>
      </div>

      <div className="grid grid-cols-3 gap-2">
        {topThree.map((item, index) => (
          <div
            key={index}
            className="bg-gray-700 p-3 rounded-lg flex flex-col items-center shadow-lg transition hover:bg-gray-600"
          >
            <FaClock className="text-blue-300 mb-1" />
            <span className="text-sm font-bold text-white">{item.month}</span>
            <span className={`font-bold ${item.downtime > 5 ? 'text-red-500' : 'text-yellow-400'} text-lg`}>
              {item.downtime}h {item.alerts > 0 ? `(${item.alerts} alerts)` : ''}
            </span>
            <span className="text-gray-400 text-xs">Location: {item.location}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NetworkUptimeDowntimeCard;
