import React from "react";
import Chart from "react-apexcharts";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faCloudSun, faMoon, faClock } from '@fortawesome/free-solid-svg-icons';

const ShiftSchedule = ({ agents }) => {
  const shiftCounts = {
    Morning: agents.filter(agent => agent.shift === "Morning" ).length,
    Afternoon: agents.filter(agent => agent.shift === "Afternoon" ).length,
    Night: agents.filter(agent => agent.shift === "Night").length,
  };

  const data = {
    series: [{
      name: "Number of Agents",
      data: [shiftCounts.Morning, shiftCounts.Afternoon, shiftCounts.Night],
    }],
    options: {
      chart: {
        type: 'bar',
        height: 250,
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 8,
          columnWidth: '50%',
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value) => value,
        style: {
          colors: ['#fff'],
          fontSize: '12px',
        },
      },
      xaxis: {
        categories: ['Morning', 'Afternoon', 'Night'],
        labels: { show: false },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#fff',
            fontSize: '12px',
          },
        },
      },
      grid: {
        borderColor: '#555',
        strokeDashArray: 4,
      },
      colors: ['#3B82F6', '#60A5FA', '#93C5FD'],
      responsive: [{
        breakpoint: 768,
        options: {
          chart: { height: 200 },
          plotOptions: { bar: { columnWidth: '40%' } },
        },
      }],
    },
  };

  return (
    <div className="ShiftSchedule bg-gray-800 rounded-lg w-full h-72  flex flex-col">
      <h3 className="text-lg font-semibold mb-4 flex items-center text-white">
        <FontAwesomeIcon icon={faClock} className="mr-2 text-blue-400" />
        Shift Schedule Distribution
      </h3>
      
      <div className="flex justify-around mb-2 text-white">
        <div className="flex flex-col items-center">
          <FontAwesomeIcon icon={faSun} className="text-yellow-400 mb-1 ml-8" />
          <span className="text-sm ml-7">Morning</span>
        </div>
        <div className="flex flex-col items-center">
          <FontAwesomeIcon icon={faCloudSun} className="text-yellow-300 mb-1" />
          <span className="text-sm">Afternoon</span>
        </div>
        <div className="flex flex-col items-center">
          <FontAwesomeIcon icon={faMoon} className="text-blue-400 mb-1" />
          <span className="text-sm">Night</span>
        </div>
      </div>
      <div className="w-full h-full">
        <Chart
          options={data.options}
          series={data.series}
          type="bar"
          height="100%"
        />
      </div>
    </div>
  );
};

export default ShiftSchedule;
