import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClock, faClipboardList, faClipboardCheck, faExclamationTriangle, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Bar, Doughnut } from 'react-chartjs-2';

const AgentAvailability = () => {
  const agents = [
    { id: 1, name: "Agent A", availability: "available", lastActive: "2024-10-23 14:00", shift: { start: "08:00", end: "16:00" }, incidents: ["Minor Injury", "Equipment Malfunction"], capabilities: ["First Aid", "Fire Safety", "Crisis Management"] },
    { id: 2, name: "Agent B", availability: "busy", lastActive: "2024-10-23 12:00", shift: { start: "09:00", end: "17:00" }, incidents: [], capabilities: ["Medical Response", "Fire Safety"] },
    { id: 3, name: "Agent C", availability: "offline", lastActive: "2024-10-22 10:00", shift: { start: "07:00", end: "15:00" }, incidents: ["Slip and Fall"], capabilities: ["Fire Safety", "Search and Rescue"] },
    { id: 4, name: "Agent D", availability: "on-break", lastActive: "2024-10-23 11:30", shift: { start: "10:00", end: "18:00" }, incidents: [], capabilities: ["Crisis Management", "Negotiation"] },
    { id: 20, name: "Agent T", availability: "busy", lastActive: "2024-10-23 12:30", shift: { start: "09:00", end: "17:00" }, incidents: ["Minor Injury"], capabilities: ["Search and Rescue", "Medical Response"] },
  ];

  const incidentData = {
    labels: agents.map(agent => agent.name),
    datasets: [{ label: 'Number of Incidents', data: agents.map(agent => agent.incidents.length), backgroundColor: 'rgba(255, 99, 132, 0.7)' }],
  };

  const availabilityCounts = agents.reduce((acc, agent) => {
    acc[agent.availability] = (acc[agent.availability] || 0) + 1;
    return acc;
  }, {});

  const availabilityData = {
    labels: Object.keys(availabilityCounts),
    datasets: [{
      data: Object.values(availabilityCounts),
      backgroundColor: ['rgba(75, 192, 192, 0.7)', 'rgba(255, 206, 86, 0.7)', 'rgba(255, 99, 132, 0.7)', 'rgba(153, 102, 255, 0.7)'],
    }],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        grid: { color: 'rgba(255, 255, 255, 0.2)' },
        ticks: { color: 'white' },
      },
      x: {
        ticks: { color: 'white' },
      },
    },
    plugins: {
      legend: {
        labels: { color: 'white' },
      },
    },
  };

  return (
    <div className="bg-gray-800 p-4 rounded-lg shadow-lg mt-6">
      <h2 className="text-xl text-white font-bold mb-4 flex items-center">
        <FontAwesomeIcon icon={faUsers} className="mr-2" />
        Agents Availability
      </h2>

      {/* Table Section */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-700 text-white mb-6 rounded-lg">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b text-left">Agent</th>
              <th className="py-2 px-4 border-b text-left">Status</th>
              <th className="py-2 px-4 border-b text-left">Last Active</th>
              <th className="py-2 px-4 border-b text-left">Shift</th>
              <th className="py-2 px-4 border-b text-left">Incidents</th>
              <th className="py-2 px-4 border-b text-left">Capabilities</th>
            </tr>
          </thead>
          <tbody>
            {agents.map(agent => (
              <tr key={agent.id} className="hover:bg-gray-600 transition duration-300">
                <td className="py-2 px-4 border-b flex items-center">
                  <FontAwesomeIcon icon={faUser} className="mr-2" />
                  {agent.name}
                </td>
                <td className={`py-2 px-4 border-b font-bold ${agent.availability === 'available' ? 'text-green-400' : agent.availability === 'busy' ? 'text-yellow-400' : agent.availability === 'offline' ? 'text-red-400' : 'text-orange-400'}`}>
                  {agent.availability}
                </td>
                <td className="py-2 px-4 border-b">
                  <FontAwesomeIcon icon={faClock} className="mr-1" />
                  {agent.lastActive}
                </td>
                <td className="py-2 px-4 border-b">{`${agent.shift.start} - ${agent.shift.end}`}</td>
                <td className="py-2 px-4 border-b">
                  <FontAwesomeIcon icon={faClipboardList} className="mr-1" />
                  {agent.incidents.length}
                </td>
                <td className="py-2 px-4 border-b">
                  <FontAwesomeIcon icon={faClipboardCheck} className="mr-1" />
                  {agent.capabilities.join(', ')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        <div className="bg-gray-800 rounded-lg p-4 flex-1" style={{ height: '250px' }}>
          <h3 className="text-xl text-white font-bold mb-2">Incident Reports Overview</h3>
          <Bar data={incidentData} options={chartOptions} />
        </div>

        <div className="bg-gray-800 rounded-lg  p-4 flex-1" style={{ height: '250px' }}>
          <h3 className="text-xl text-white font-bold mb-2">Agent Availability Distribution</h3>
          <Doughnut data={availabilityData} options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
                labels: { color: 'white' },
              },
            },
          }} />
        </div>

        <div className="bg-gray-800 rounded-lg  p-4 flex-1" style={{ height: '250px' }}>
  <h3 className="text-xl text-white font-bold mb-2">Incident Details</h3>
  <ul className="overflow-y-auto max-h-full text-sm space-y-2">
    {agents.map(agent => (
      agent.incidents.length > 0 && (
        <li key={agent.id} className="flex justify-between border-b border-gray-600 pb-1">
          <div className="text-white">
            <FontAwesomeIcon icon={faUser} className="mr-2 text-blue-500" />
            {agent.name}
          </div>
          <div className="text-gray-300">
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1 text-yellow-400" />
            {agent.incidents[0]} {/* Show only the first incident */}
          </div>
        </li>
      )
    ))}
    {agents.every(agent => agent.incidents.length === 0) && (
      <li className="text-gray-400 text-center">No incidents reported by any agents.</li>
    )}
  </ul>
</div>

      </div>
    </div>
  );
};

export default AgentAvailability;
