import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire, faExclamationTriangle, faShieldAlt, faUser, faSignal, faBell } from '@fortawesome/free-solid-svg-icons';

const alertsData = [
  { severity: "Red", system: "Fire", description: "Fire alarm triggered in Server Room 1.", timestamp: new Date().toLocaleString() },
  { severity: "Orange", system: "IT", description: "Network instability detected in Server Room 2.", timestamp: new Date().toLocaleString() },
  { severity: "Yellow", system: "Security", description: "Motion detected at Parking A entrance.", timestamp: new Date().toLocaleString() },
  { severity: "Green", system: "Fire", description: "Fire alarm acknowledged and cleared at Parking B.", timestamp: new Date().toLocaleString() },
  { severity: "Red", system: "Fire", description: "Smoke detected in Server Room 2.", timestamp: new Date().toLocaleString() },
  { severity: "Red", system: "IT", description: "Critical server down in Data Center.", timestamp: new Date().toLocaleString() },
  { severity: "Orange", system: "Security", description: "Unauthorized access attempt detected.", timestamp: new Date().toLocaleString() },
  { severity: "Yellow", system: "Maintenance", description: "Scheduled maintenance in progress.", timestamp: new Date().toLocaleString() },
  { severity: "Green", system: "IT", description: "Backup completed successfully.", timestamp: new Date().toLocaleString() },
  { severity: "Orange", system: "Fire", description: "Fire drill scheduled for next week.", timestamp: new Date().toLocaleString() },
];

const SystemAlerts = () => {
  const [filter, setFilter] = useState("All");

  const filteredAlerts = filter === "All" ? alertsData : alertsData.filter(alert => alert.severity === filter);

  const totalAlerts = filteredAlerts.length;
  const redAlerts = filteredAlerts.filter(alert => alert.severity === 'Red').length;
  const orangeAlerts = filteredAlerts.filter(alert => alert.severity === 'Orange').length;
  const yellowAlerts = filteredAlerts.filter(alert => alert.severity === 'Yellow').length;
  const greenAlerts = filteredAlerts.filter(alert => alert.severity === 'Green').length;

  return (
    <div className="p-4 rounded-lg bg-gray-800 text-gray-200 relative flex flex-col mt-12">
      <div className="bg-gray-800 rounded-md shadow-md p-2 flex-grow">
        <div className="flex items-center mb-2">
          <FontAwesomeIcon icon={faBell} className="text-blue-400 text-lg mr-1" />
          <h3 className="text-lg font-semibold">System Alerts</h3>
        </div>

     

        <div className="grid grid-cols-2 gap-1 mb-1">
          {[ 
            { icon: faFire, color: 'red-400', label: 'Red Alerts', count: redAlerts },
            { icon: faExclamationTriangle, color: 'orange-400', label: 'Orange Alerts', count: orangeAlerts },
            { icon: faShieldAlt, color: 'yellow-400', label: 'Yellow Alerts', count: yellowAlerts },
            { icon: faUser, color: 'green-400', label: 'Green Alerts', count: greenAlerts },
            { icon: faSignal, color: 'blue-400', label: 'Total Alerts', count: totalAlerts },
          ].map(({ icon, color, label, count }) => (
            <div key={label} className="flex items-center bg-gray-700 rounded p-1 shadow hover:bg-gray-600 transition duration-200 text-xs">
              <FontAwesomeIcon icon={icon} className={`text-${color} text-md mr-1`} />
              <span className="text-white">{label}: <span className="font-bold">{count}</span></span>
            </div>
          ))}
        </div>

        <div className="mt-1 max-h-24 overflow-y-auto"> {/* Adjusted height here */}
          <h4 className="text-gray-400 text-xs font-semibold mb-1">Alert Details:</h4>
          {filteredAlerts.map((alert, index) => (
            <div key={index} className="bg-gray-600 rounded p-1 mb-1 text-xs text-white">
              <p><strong>{alert.severity}:</strong> {alert.description}</p>
              <span className="text-gray-300 text-xs">{alert.timestamp}</span>
            </div>
          ))}
        </div>

        <div className="mt-1 text-center text-gray-400 text-xs">
          <span>Last updated: {new Date().toLocaleString()}</span>
        </div>
      </div>
    </div>
  );
};

export default SystemAlerts;
