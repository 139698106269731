import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../img/Magnetoo-logo.jpeg';
import {
  faShieldAlt,
  faBuilding,
  faUserTie,
  faServer,
  faParking,
} from '@fortawesome/free-solid-svg-icons';

const Ooda_Side_Bar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={`fixed inset-y-0 left-0 z-[60] transition-transform duration-300 ease-out bg-gray-800 text-white shadow-xl ${isOpen ? 'w-64' : 'w-20'}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Logo Section */}
      <div className="flex items-center justify-center p-4">
        <img
          src={logo}
          alt="Logo"
          className="rounded-full w-10 h-10"
        />
      </div>

      {/* Navigation Links */}
      <nav className="mt-10 flex flex-col space-y-6">
        {[
          { href: '/security-systems', icon: faShieldAlt, label: 'Alarms' },
          { href: '/bms', icon: faBuilding, label: 'Agents' },
          { href: '/admin', icon: faUserTie, label: 'Security Systems' },
          { href: '/it', icon: faServer, label: 'IT Network & Systems' },
          { href: '/building-management', icon: faParking, label: 'Building Management Systems' },
          { href: '/car-parking', icon: faParking, label: 'Car Parking' },
        ].map(({ href, icon, label }) => (
          <a
            key={label}
            href={href}
            className="flex items-center p-2 hover:bg-gray-700 rounded-lg transition duration-300 ease-out w-full text-gray-200"
            style={{ textDecoration: 'none' }} // Remove underline
          >
            <div className="flex items-center w-full">
              <FontAwesomeIcon icon={icon} className="text-white text-lg ml-4" />
              <span className={`ml-2 font-medium transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}>
                {label}
              </span>
            </div>
          </a>
        ))}
      </nav>

      {/* Footer */}
      <div className="absolute bottom-0 left-0 w-full p-4 text-center">
        <p className="text-xs text-gray-400">© Ooda 2024</p>
      </div>
    </div>
  );
};

export default Ooda_Side_Bar;
