import React, { useState, useEffect } from 'react';

const Filters = ({ onFilterChange }) => {
  const [selectedArea, setSelectedArea] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedAgent, setSelectedAgent] = useState('');

  useEffect(() => {
    // Trigger filter change whenever any filter changes
    onFilterChange({ selectedArea, selectedCategory, selectedAgent });
  }, [selectedArea, selectedCategory, selectedAgent]);

  return (
    <div className="flex flex-row space-x-4 p-4 bg-gray-800 rounded-lg items-center">
      <div className="flex flex-col">
        <label className="text-gray-400 text-xs mb-1">Filter by Area:</label>
        <select
          value={selectedArea}
          onChange={(e) => setSelectedArea(e.target.value)}
          className="bg-gray-700 text-white text-xs p-2 rounded focus:ring-1 focus:ring-blue-500 focus:outline-none transition ease-in-out duration-150"
        >
          <option value="">All Areas</option>
          <option value="north">North</option>
          <option value="south">South</option>
          {/* Add more areas as needed */}
        </select>
      </div>

      <div className="flex flex-col">
        <label className="text-gray-400 text-xs mb-1">Filter by Category:</label>
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          className="bg-gray-700 text-white text-xs p-2 rounded focus:ring-1 focus:ring-blue-500 focus:outline-none transition ease-in-out duration-150"
        >
          <option value="">All Categories</option>
          <option value="category1">Category 1</option>
          <option value="category2">Category 2</option>
          <option value="category3">Category 3</option>
        </select>
      </div>

      <div className="flex flex-col">
        <label className="text-gray-400 text-xs mb-1">Filter by Agent:</label>
        <select
          value={selectedAgent}
          onChange={(e) => setSelectedAgent(e.target.value)}
          className="bg-gray-700 text-white text-xs p-2 rounded focus:ring-1 focus:ring-blue-500 focus:outline-none transition ease-in-out duration-150"
        >
          <option value="">All Agents</option>
          <option value="available">Available</option>
          <option value="busy">Busy</option>
          <option value="offline">Offline</option>
        </select>
      </div>
    </div>
  );
};

export default Filters;
