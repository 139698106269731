import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const ViolationDonutChartAlmuteena = ({ darkMode }) => {
  const [violationData, setViolationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const jsonFilePath = process.env.PUBLIC_URL + '/data summary.json';
    document.body.style.backgroundColor = 'transparent';
    document.getElementById('root').style.backgroundColor = 'transparent';


    fetch(jsonFilePath)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((jsonData) => {
        setViolationData(jsonData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error loading the JSON file:', error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  const aggregateData = (data) => {
    let totalAlmuteenaViolations = 0;

    data.forEach(item => {
      // Aggregate violations for September (9) and October (10)
      if (item.month === 9 || item.month === 10) {
        if (item.area === "Almuteena") {
          totalAlmuteenaViolations += item.total_violations;
        }
      }
    });

    return totalAlmuteenaViolations;
  };

  const totalAlmuteenaViolations = aggregateData(violationData);
  const totalViolationsAllAreas = violationData.reduce((sum, item) => {
    if (item.month === 9 || item.month === 10) {
      return sum + item.total_violations;
    }
    return sum;
  }, 0);

  const percentageAlmuteena = totalViolationsAllAreas > 0 ? (totalAlmuteenaViolations / totalViolationsAllAreas) * 100 : 0;

  const series = [percentageAlmuteena, 100 - percentageAlmuteena];
  const labels = ['Almuteena', 'Others'];

  const options = {
    chart: {
      type: 'donut',
      background: 'transparent',
      foreColor: darkMode ? '#fff' : '#000',
    },
    colors: ['#F56565', '#E2E8F0'], // Red for Almuteena, light gray for others
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(2)}%`, // Display percentage
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: darkMode ? '#fff' : '#000',
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      labels: {
        colors: darkMode ? '#fff' : '#000',
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
        },
      },
    },
    labels: labels,
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <div className={`p-4 rounded-lg ${darkMode ? 'bg-gray-800/80 text-gray-200' : 'bg-white/30 text-gray-900'} relative`} style={{ width: '386px' }}>
      <div className="text-sm font-semibold mb-1 text-left" style={{ textShadow: '1px 1px 2px rgba(0,0,0,0.5)', marginBottom: '5px' }}>
        Violations Overview for Almuteena (Sept & Oct)
      </div>
      <div className="relative w-full" style={{ height: '185px' }}>
        <Chart options={options} series={series} type="donut" height="200" />
      </div>
    </div>
  );
};

export default ViolationDonutChartAlmuteena;
