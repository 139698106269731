import React from "react";
import { Bar } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register components for Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const PanicButtonActivationChart = ({ data }) => {
  // Chart data
  const chartData = {
    labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"], // Example for daily activations
    datasets: [
      {
        label: "Panic Button Activations",
        data: data, // Data passed from props
        backgroundColor: 'rgba(255, 99, 132, 0.8)', // Red for emergency-related events
        hoverBackgroundColor: 'rgba(255, 99, 132, 1)', // Brighter red on hover
        borderRadius: 10, // Rounded corners for bars
        barThickness: 30, // Adjust bar thickness
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allow chart to adapt to the container
    plugins: {
      legend: {
        display: false, // Remove legend for simplicity
      },
      tooltip: {
        enabled: true,
        backgroundColor: '#fff', // White background for modern tooltips
        bodyColor: '#000', // Black text for contrast
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1,
        titleFont: { size: 14 },
        bodyFont: { size: 12 },
        boxPadding: 10,
        caretPadding: 8,
        padding: 10,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove X-axis grid lines
        },
        ticks: {
          font: {
            size: 14,
            weight: 'bold',
          },
          color: '#ddd', // Lighter color for X-axis labels
        },
      },
      y: {
        grid: {
          display: false, // Remove Y-axis grid lines
        },
        ticks: {
          font: {
            size: 14,
            weight: 'bold',
          },
          color: '#ddd', // Lighter color for Y-axis labels
        },
      },
    },
    animation: {
      duration: 1000, // Fast and smooth animation
      easing: 'easeOutQuart', // Modern easing animation
    },
  };

  return (
    <div className="relative w-full h-64 bg-gray-800 p-6 rounded-lg shadow-lg">
      {/* Title and Icon */}
      <div className="flex items-center mb-4">
        <FontAwesomeIcon icon={faBan} className="text-xl text-red-500 mr-2" />
        <h3 className="text-lg font-semibold text-white">Panic Button Activation</h3>
      </div>

      {/* Bar Chart */}
      <div className="h-48">
        <Bar data={chartData} options={options} />
      </div>
    </div>
  );
};

export default PanicButtonActivationChart;
