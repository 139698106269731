import React from "react";
import { Bar } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register components for Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SystemFailuresBarChart = ({ online, offline, malfunctioning }) => {
  // Data for the bar chart
  const chartData = {
    labels: ['Online', 'Offline', 'Malfunctioning'],
    datasets: [
      {
        label: 'System Status',
        data: [online, offline, malfunctioning],
        backgroundColor: [
          'rgba(75, 192, 192, 0.9)',  // Soft green for online
          'rgba(255, 99, 132, 0.9)',  // Soft red for offline
          'rgba(255, 206, 86, 0.9)',  // Soft yellow for malfunctioning
        ],
        hoverBackgroundColor: [
          'rgba(75, 192, 192, 1)',    // Brighter green on hover
          'rgba(255, 99, 132, 1)',    // Brighter red on hover
          'rgba(255, 206, 86, 1)',    // Brighter yellow on hover
        ],
        borderRadius: 15, // Rounded corners for bars
        borderWidth: 0,  // Remove borders for a cleaner look
        barThickness: 40, // Adjusted bar thickness for a better fit
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    indexAxis: 'y', // Horizontal bar chart
    maintainAspectRatio: false, // Let the chart take up more space
    plugins: {
      legend: {
        display: false, // Remove legend for simplicity
      },
      tooltip: {
        enabled: true,
        backgroundColor: '#333', // Dark tooltip background
        bodyColor: '#fff', // Light text for better contrast
        borderColor: 'rgba(255, 255, 255, 0.1)',
        borderWidth: 1,
        titleFont: { size: 14 },
        bodyFont: { size: 12 },
        boxPadding: 10,
        caretPadding: 8,
        padding: 10,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove X axis grid lines
        },
        ticks: {
          font: {
            size: 14,
            weight: 'bold',
          },
          color: '#aaa', // Lighter color for X-axis labels
        },
      },
      y: {
        grid: {
          display: false, // Remove Y axis grid lines
        },
        ticks: {
          font: {
            size: 14,
            weight: 'bold',
          },
          color: '#ddd', // Lighter color for Y-axis labels
        },
      },
    },
    animation: {
      duration: 1000, // Fast and smooth animation
      easing: 'easeOutQuart', // Modern easing animation
    },
  };

  return (
    <div className="relative w-full h-72 bg-gray-800 p-6 ">
      {/* Title with Icon */}
      <div className="flex items-center mb-4">
        <FontAwesomeIcon icon={faCogs} className="text-xl text-blue-500 mr-2" />
        <h3 className="text-lg font-semibold text-white">Failures</h3> {/* Removed "System" */}
      </div>
      
      {/* Bar Chart */}
      <div className="h-64">
        <Bar data={chartData} options={options} />
      </div>
    </div>
  );
};

export default SystemFailuresBarChart;
