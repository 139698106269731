import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const ViolationDonutChartParis = ({ darkMode }) => {
  const [donutData, setDonutData] = useState([]);

  useEffect(() => {
    // Simulated data for violations
    const simulatedData = [
      { label: 'Speeding', value: 40 },
      { label: 'Parking', value: 30 },
      { label: 'Red Light', value: 20 },
      { label: 'Other', value: 10 },
    ];

    setDonutData(simulatedData);
  }, []);

  const series = donutData.map(data => data.value);
  const labels = donutData.map(data => data.label);

  const options = {
    chart: {
      type: 'donut',
      background: 'transparent',
    },
    colors: ['#48BB78', '#FF5733', '#33FF57', '#3357FF'], // Consistent color with other charts
    labels: labels,
    plotOptions: {
      pie: {
        donut: {
          size: '60%',
        },
      },
    },
    legend: {
      position: 'bottom',
      labels: {
        colors: darkMode ? '#fff' : '#000',
      },
    },
  };

  return (
    <div className={`p-4 rounded-lg ${darkMode ? 'bg-gray-800/80 text-gray-200' : 'bg-white/30 text-gray-900'} relative`} style={{ width: '386px' }}>
       <div className="text-sm font-semibold mb-1 text-left" style={{ textShadow: '1px 1px 2px rgba(0,0,0,0.5)', marginBottom: '5px' }}>
        Violations Overview for Paris 
      </div>
      <div className="relative w-full" style={{ height: '185px' }}>
        <Chart options={options} series={series} type="donut" height="200" />
      </div>
    </div>
  );
};

export default ViolationDonutChartParis;
