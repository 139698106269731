import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, DivIcon } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './LeafletMap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faExclamationCircle, faBell, faCheckCircle, faMap } from '@fortawesome/free-solid-svg-icons';
import L from 'leaflet';

const getSeverityIcon = (severity) => {
  switch (severity) {
    case 'Red':
      return faExclamationTriangle; // Critical alarm
    case 'Orange':
      return faExclamationCircle; // Major alarm
    case 'Yellow':
      return faBell; // Minor alarm
    case 'Green':
      return faCheckCircle; // Cleared or acknowledged alarm
    default:
      return faBell; // Default fallback icon
  }
};

const createCustomIcon = (alarm) => {
  return new L.DivIcon({
    className: 'custom-marker',
    html: `
      <div class="fa-icon-marker" style="color: ${getIconColor(alarm.severity)};">
        <i class="${getFontAwesomeClass(alarm.severity)}"></i>
      </div>
      <div class="marker-legend">
        <strong>${alarm.severity} Alarm</strong><br>
        ${alarm.description}<br>
        <em>${alarm.timestamp}</em>
      </div>`,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -35],
  });
};

const getFontAwesomeClass = (severity) => {
  switch (severity) {
    case 'Red':
      return 'fas fa-exclamation-triangle';
    case 'Orange':
      return 'fas fa-exclamation-circle';
    case 'Yellow':
      return 'fas fa-bell';
    case 'Green':
      return 'fas fa-check-circle';
    default:
      return 'fas fa-bell';
  }
};

const getIconColor = (severity) => {
  switch (severity) {
    case 'Red':
      return '#ff4c4c'; // Red color for critical alarm
    case 'Orange':
      return '#ffa500'; // Orange color for major alarm
    case 'Yellow':
      return '#ffff00'; // Yellow color for minor alarm
    case 'Green':
      return '#00ff00'; // Green color for cleared alarm
    default:
      return 'gray'; // Default gray color
  }
};

const LeafletMap = ({ data }) => {
  return (
    <div className="map-container h-full w-full">
      {/* Removed title section */}

      <MapContainer
        center={[36.77455555422348, 10.220700057241274]} // Example default center coordinates
        zoom={130} // Adjust zoom level
        scrollWheelZoom={false}
        style={{ height: '100%', width: '100%' }}
      >
        <TileLayer
          url="http://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          attribution='&copy; <a href="https://www.google.com/permissions/geoguidelines.html">Google</a>'
        />

        {data.map((alarm) => (
          <Marker
            key={alarm.id}
            position={[alarm.lat, alarm.lng]}
            icon={createCustomIcon(alarm)}
          >
            <Popup className="custom-popup">
              <div className="popup-content">
                <strong>{alarm.severity} Alarm</strong> <br />
                <p className="popup-description">{alarm.description}</p>
                <em>{alarm.timestamp}</em>
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default LeafletMap;
