import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

// Sample fake data
const fakeData = {
  deviceFailures: [
    { id: 1, deviceName: 'Temperature Sensor', status: 'offline', severity: 'minor', lastUpdate: '2024-10-01 12:10:00', description: 'No signal detected' },
    { id: 2, deviceName: 'Fire Alarm Sensor', status: 'malfunctioning', severity: 'critical', lastUpdate: '2024-10-01 12:15:00', description: 'Battery low' },
    { id: 3, deviceName: 'Door Sensor', status: 'offline', severity: 'major', lastUpdate: '2024-10-01 12:20:00', description: 'Disconnected' },
    { id: 4, deviceName: 'Camera', status: 'malfunctioning', severity: 'critical', lastUpdate: '2024-10-01 12:25:00', description: 'Lens obstruction' },
  ],
};

const severityIcons = {
  critical: faExclamationTriangle,
  major: faExclamationCircle,
  minor: faCheckCircle,
};

const DeviceFailuresCard = ({ data = fakeData }) => {
  if (!data || !data.deviceFailures) {
    return <div className="text-white text-center">Loading device failures...</div>;
  }

  return (
    <div className="bg-gray-800 p-4 rounded-lg shadow-lg">
      <div className="flex items-center mb-2">
        <FontAwesomeIcon icon={faExclamationTriangle} className="text-yellow-500 text-2xl mr-2" />
        <h2 className="text-white text-lg">Device Failures Overview</h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        {data.deviceFailures.map((device) => (
          <div key={device.id} className={`bg-gray-700 p-2 rounded-lg flex items-center shadow`}>
            <FontAwesomeIcon icon={severityIcons[device.severity]} className="text-white text-lg mr-2" />
            <div className="flex flex-col">
              <span className="text-white font-semibold text-sm">{device.deviceName}</span>
              <span className={`text-xs ${device.status === 'offline' ? 'text-red-500' : 'text-yellow-500'}`}>
                Status: {device.status.charAt(0).toUpperCase() + device.status.slice(1)}
              </span>
              <span className="text-xs text-gray-400">Last: {device.lastUpdate}</span>
              <span className="text-xs text-gray-400">Desc: {device.description}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeviceFailuresCard;
