import React from 'react';
import { FaWater, FaExclamationTriangle, FaLocationArrow } from 'react-icons/fa';

const WaterLeakAlertsCard = () => {
  // Fake monthly data for Water Leak Alerts
  const data = [
    { month: "Jan", alerts: 0, location: "Basement" },
    { month: "Feb", alerts: 1, location: "Floor 1" },
    { month: "Mar", alerts: 2, location: "Floor 2" },
    { month: "Apr", alerts: 5, location: "Roof" },
    { month: "May", alerts: 3, location: "Floor 3" },
    { month: "Jun", alerts: 6, location: "Kitchen" },
    { month: "Jul", alerts: 8, location: "Bathroom" },
    { month: "Aug", alerts: 2, location: "Garage" },
    { month: "Sep", alerts: 4, location: "Floor 4" },
    { month: "Oct", alerts: 7, location: "Basement" },
    { month: "Nov", alerts: 0, location: "Floor 1" },
    { month: "Dec", alerts: 1, location: "Floor 2" },
  ];

  // Sort and get top data
  const sortedData = data.sort((a, b) => b.alerts - a.alerts);
  const topThree = sortedData.slice(0, 3);
  const highestAlert = sortedData[0];

  return (
    <div className="bg-gray-800 rounded-xl p-4 transition duration-300 ease-in-out transform">
      {/* Title at the top */}
      <h2 className="text-xl font-extrabold mb-4 flex items-center text-white">
        <FaWater className="mr-2 text-blue-300" /> Water Leak Alerts
      </h2>

      {/* Highest alert section */}
      <div className="flex items-center mb-4">
        <FaExclamationTriangle className="text-yellow-400 mr-2" />
        <span className="text-white font-bold">
          Highest Leak: {highestAlert.alerts} alerts at {highestAlert.location}
        </span>
      </div>

      {/* Top 3 alerts section */}
      <div className="grid grid-cols-3 gap-2">
        {topThree.map((item, index) => (
          <div
            key={index}
            className="bg-gray-700 p-3 rounded-lg flex flex-col items-center shadow-lg transition hover:bg-gray-600"
          >
            <FaLocationArrow className="text-blue-300 mb-1" />
            <span className="text-sm font-bold text-white">{item.month}</span>
            <span className={`font-bold ${item.alerts > 5 ? 'text-red-500' : 'text-yellow-400'} text-lg`}>
              {item.alerts} {item.alerts > 5 ? '⚠️' : ''}
            </span>
            <span className="text-gray-400 text-xs">Location: {item.location}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WaterLeakAlertsCard;
