import React, { useState } from "react";
import ShiftSchedule from "./charts/Agents/ShiftSchedule";
import AgentCapabilityDonutChart from "./charts/Agents/Agenetcc";
import OKPI from "./components/Kpi";
import {
  faBars,
  faCheckCircle,
  faClock,
  faExclamationCircle,
  faMapMarker,
  faMapMarkerAlt,
  faShieldAlt,
  faTag,
  faTimesCircle,
  faUser,
  faUsers,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import Filters from "./charts/Agents/Filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilteredAgentsTable from "./charts/Agents/FiltredTable";
import logo from "../../img/color1_text-logoname_transparent_background.png";
import PerformanceMetrics from "./charts/Agents/PerformanceMetrics";
import MissionsOverview from "./charts/Agents/MissionsOverview";
import IncidentReports from "./charts/Agents/IncidentReports";
import AgentAvailability from "./charts/Agents/AgentAvailability";
import AgentStatsCard from "./charts/Agents/AgentStatsCard";

// Import other components and icons...

const OodaAgents = () => {
  const [filters, setFilters] = useState({
    selectedArea: "",
    selectedCategory: "",
    selectedAgent: "",
  });

  const agents = [
    {
        id: 1,
        name: "Agent A",
        availability: "available",
        area: "north",
        category: "category1",
        shift: "Morning",
        onMission: false,
        resolvedIssues: 12,
        responseTime: 5.5,
        performanceRating: 4.8,
    },
    {
        id: 2,
        name: "Agent B",
        availability: "busy",
        area: "south",
        category: "category2",
        shift: "Afternoon",
        onMission: true,
        resolvedIssues: 8,
        responseTime: 6.0,
        performanceRating: 4.2,
    },
    {
        id: 3,
        name: "Agent C",
        availability: "offline",
        area: "north",
        category: "category3",
        shift: "Morning",
        onMission: false,
        resolvedIssues: 0,
        responseTime: 0,
        performanceRating: 0,
    },
    {
        id: 4,
        name: "Agent D",
        availability: "available",
        area: "south",
        category: "category1",
        shift: "Night",
        onMission: false,
        resolvedIssues: 15,
        responseTime: 4.0,
        performanceRating: 4.9,
    },
    {
        id: 5,
        name: "Agent E",
        availability: "busy",
        area: "north",
        category: "category2",
        shift: "Afternoon",
        onMission: true,
        resolvedIssues: 10,
        responseTime: 7.2,
        performanceRating: 3.8,
    },
    {
        id: 6,
        name: "Agent F",
        availability: "offline",
        area: "south",
        category: "category3",
        shift: "Afternoon",
        onMission: false,
        resolvedIssues: 0,
        responseTime: 0,
        performanceRating: 0,
    },
    {
        id: 7,
        name: "Agent G",
        availability: "available",
        area: "north",
        category: "category1",
        shift: "Morning",
        onMission: false,
        resolvedIssues: 20,
        responseTime: 3.5,
        performanceRating: 4.6,
    },
    {
        id: 8,
        name: "Agent H",
        availability: "busy",
        area: "south",
        category: "category2",
        shift: "Afternoon",
        onMission: true,
        resolvedIssues: 5,
        responseTime: 8.0,
        performanceRating: 3.5,
    },
    {
        id: 9,
        name: "Agent I",
        availability: "offline",
        area: "north",
        category: "category3",
        shift: "Afternoon",
        onMission: false,
        resolvedIssues: 0,
        responseTime: 0,
        performanceRating: 0,
    },
    {
        id: 10,
        name: "Agent J",
        availability: "available",
        area: "south",
        category: "category1",
        shift: "Morning",
        onMission: false,
        resolvedIssues: 10,
        responseTime: 5.0,
        performanceRating: 4.3,
    },
    {
        id: 11,
        name: "Agent K",
        availability: "busy",
        area: "north",
        category: "category2",
        shift: "Afternoon",
        onMission: true,
        resolvedIssues: 12,
        responseTime: 6.5,
        performanceRating: 4.1,
    },
    {
        id: 12,
        name: "Agent L",
        availability: "offline",
        area: "south",
        category: "category3",
        shift: "Afternoon",
        onMission: false,
        resolvedIssues: 0,
        responseTime: 0,
        performanceRating: 0,
    },
    {
        id: 13,
        name: "Agent M",
        availability: "available",
        area: "north",
        category: "category1",
        shift: "Night",
        onMission: false,
        resolvedIssues: 5,
        responseTime: 4.5,
        performanceRating: 4.7,
    },
    {
        id: 14,
        name: "Agent N",
        availability: "busy",
        area: "south",
        category: "category2",
        shift: "Afternoon",
        onMission: true,
        resolvedIssues: 8,
        responseTime: 7.0,
        performanceRating: 3.9,
    },
    {
        id: 15,
        name: "Agent O",
        availability: "offline",
        area: "north",
        category: "category3",
        shift: "Morning",
        onMission: false,
        resolvedIssues: 0,
        responseTime: 0,
        performanceRating: 0,
    },
    {
        id: 16,
        name: "Agent P",
        availability: "available",
        area: "south",
        category: "category1",
        shift: "Afternoon",
        onMission: false,
        resolvedIssues: 10,
        responseTime: 5.2,
        performanceRating: 4.4,
    },
    {
        id: 17,
        name: "Agent Q",
        availability: "busy",
        area: "north",
        category: "category2",
        shift: "Night",
        onMission: true,
        resolvedIssues: 3,
        responseTime: 9.0,
        performanceRating: 3.0,
    },
    {
        id: 18,
        name: "Agent R",
        availability: "offline",
        area: "south",
        category: "category3",
        shift: "Morning",
        onMission: false,
        resolvedIssues: 0,
        responseTime: 0,
        performanceRating: 0,
    },
    {
        id: 19,
        name: "Agent S",
        availability: "available",
        area: "north",
        category: "category1",
        shift: "Night",
        onMission: false,
        resolvedIssues: 15,
        responseTime: 4.2,
        performanceRating: 4.8,
    },
    {
        id: 20,
        name: "Agent T",
        availability: "busy",
        area: "south",
        category: "category2",
        shift: "Night",
        onMission: true,
        resolvedIssues: 7,
        responseTime: 6.8,
        performanceRating: 3.6,
    },
];


  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  const filteredAgents = agents.filter((agent) => {
    return (
      (!filters.selectedArea || agent.area === filters.selectedArea) &&
      (!filters.selectedCategory ||
        agent.category === filters.selectedCategory) &&
      (!filters.selectedAgent || agent.availability === filters.selectedAgent)
    );
  });

  return (
    <div className="flex h-screen bg-gray-900 ml-20">
      <header className="fixed top-0 left-0 right-0 z-50 flex justify-between p-4 shadow-md bg-gray-900 mb-4">
      <div className="flex items-center ml-28">
        <FontAwesomeIcon icon={faUsers} className="text-xl text-white mr-6" />
        <span className="text-white text-2xl font-semibold">Agents</span>
      </div>
      <div className="flex items-center">
        <img src={logo} alt="Logo" className="h-16 w-auto" />
      </div>
    </header>

      <div className="flex-1 flex flex-col overflow-y-auto bg-gray-900 mt-20 p-4">
        <br></br>
        <Filters onFilterChange={handleFilterChange} />

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
          <OKPI
            title="Total Agents"
            value={filteredAgents.length} // Total number of filtered agents
            icon={faUserTie}
            bgColor="bg-gradient-to-r from-blue-500 to-blue-700"
          />
          <OKPI
            title="Active Agents"
            value={
              filteredAgents.filter(
                (agent) => agent.availability === "available"
              ).length
            } // Count of active agents
            icon={faShieldAlt}
            bgColor="bg-gradient-to-r from-green-500 to-green-700"
          />
          <OKPI
            title="Offline Agents"
            value={
              filteredAgents.filter((agent) => agent.availability === "offline")
                .length
            } // Count of offline agents
            icon={faExclamationCircle}
            bgColor="bg-gradient-to-r from-red-500 to-red-700"
          />
          <OKPI
            title="In-Mission Agents"
            value={filteredAgents.filter((agent) => agent.onMission).length} // Count of agents on a mission
            icon={faUserTie}
            bgColor="bg-gradient-to-r from-orange-500 to-orange-700"
          />
        </div>

        <div className="w-full px-0 mt-6">
          <div className="grid grid-cols-3 gap-4">
            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              <ShiftSchedule agents={filteredAgents} />
            </div>
            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              <AgentCapabilityDonutChart agents={filteredAgents} />
            </div>
            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              <AgentStatsCard  agents={agents} />
            </div>
          </div>
          <FilteredAgentsTable filteredAgents={filteredAgents} />

          <div>
            <PerformanceMetrics filters={filters} />
            <IncidentReports filters={filters} />
            <MissionsOverview filters={filters} />
            <AgentAvailability filters={filters} />
          </div>
        </div>

        {/* Display filtered agents */}
      </div>
    </div>
  );
};

export default OodaAgents;
