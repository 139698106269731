import React, { useState } from "react";

import {
  faExclamationCircle,
  faVideo,
  faBell,
  faUserSecret,
  faCogs,
  faBars,
  faChartLine,
  faBan,
  faCheckCircle,
  faShieldAlt,
  faFireAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../img/color1_text-logoname_transparent_background.png";
import OKPI from "./components/Kpi";
import BarChart from "./charts/SecuritySystems/Barchart";
import DeviceFailuresChart from "./charts/SecuritySystems/Devicefailurechart";
import SystemFailuresBarChart from "./charts/SecuritySystems/Systemfailurechart";
import PanicButtonActivationChart from "./charts/SecuritySystems/Panicbuttonchart";
import DataTable from "./charts/SecuritySystems/DataTable";

const SecuritySystems = () => {
  const [isOpen, setIsOpen] = useState(true); 
 
  const onlineDevices = 92;
  const offlineDevices = 8;
  const onlineSystems = 92;
  const offlineSystems = 8;
  const data = {
    totalSystemsMonitored: 120,
    activeAlerts: 15,
    devicesOffline: 8,
    unauthorizedEntries: 5,
    onlineSystems: 92,
    offlineSystems: 8,
    malfunctioningSystems: 5,
    panicButtonActivations: 7,
  };
  const panicButtonData = [5, 3, 8, 2, 6, 7, 4]; 

  const malfunctioningSystems = 5;
  const systemAlertData = {
    CCTV: 10,   
    ACS: 8,     
    IDS: 12,  
    Fire: 5,    
    Agents: 7,  
  };
  

  return (
    <div className="flex h-screen w-full bg-gray-900">
      {/* Fixed Header */}
      <header className="ml-20 fixed top-0 left-0 right-0 z-50 flex justify-between items-center p-4 shadow-md bg-gray-900 mb-4">
        <button className="md:hidden p-2" onClick={() => setIsOpen(!isOpen)}>
          <FontAwesomeIcon icon={faBars} className="text-xl" />
        </button>
        <div className="text-xl font-semibold text-white">Security Systems Dashboard</div>
        <div className="flex items-center">
          <img src={logo} alt="Logo" className="h-16 w-auto" />
        </div>
      </header>
  
      {/* Sidebar */}
      <div className="ml-20 z-50">
      </div>
  
      {/* Main Content Area */}
      <div className="flex-1 flex flex-col overflow-y-auto bg-gray-900 mt-20 p-4">
        {/* Top KPI Grid - Displaying counts */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
          {/* Total Systems Monitored */}
          <OKPI
            title="Total Systems Monitored"
            value="120" // Placeholder data
            icon={faCogs} // Icon representing systems
            bgColor="bg-gradient-to-r from-gray-500 to-gray-700" // Gray theme for system monitoring
          />
          
          {/* Active Alerts */}
          <OKPI
            title="Active Alerts"
            value="15" // Placeholder data for active alerts
            icon={faBell} // Icon representing alerts
            bgColor="bg-gradient-to-r from-red-500 to-orange-500" // Red/orange theme for alerts
          />
  
          {/* Devices Offline */}
          <OKPI
            title="Devices Offline"
            value="8" // Placeholder data for devices offline
            icon={faExclamationCircle} // Icon representing warnings or offline status
            bgColor="bg-gradient-to-r from-yellow-500 to-orange-500" // Yellow theme for offline devices
          />
          
          {/* Unauthorized Entries */}
          <OKPI
            title="Unauthorized Entries"
            value="5" // Placeholder data for unauthorized entries
            icon={faUserSecret} // Icon representing unauthorized access attempts
            bgColor="bg-gradient-to-r from-blue-500 to-indigo-500" // Blue theme for security breaches
          />
        </div>
  
        {/* Middle KPI Grid - Data Visualization Sections */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
          {/* System Failures - Chart */}
          <div className="bg-gray-800 p-4 rounded-lg shadow-lg">
            <SystemFailuresBarChart online={onlineSystems} offline={offlineSystems} malfunctioning={malfunctioningSystems} />
          </div>
  
          {/* Device Failures - Chart */}
          <div className="bg-gray-800 p-4 rounded-lg shadow-lg">
            <DeviceFailuresChart online={onlineDevices} offline={offlineDevices} />
          </div>
  
          {/* System Alerts - Table */}
          <div className="bg-gray-800 p-4 rounded-lg shadow-lg">
            <BarChart data={systemAlertData} darkMode={true} title="Alarms by Severity" />
          </div>
        </div>
  
        {/* Detailed View Section - Expand for full width */}
        <div className="mt-6">
        <DataTable data={data} />

        </div>
      </div>
    </div>
  );
  
};

export default SecuritySystems;
