import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend } from 'chart.js';

ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend);

const OKPI = ({ title, value, icon, bgColor = 'bg-gradient-to-r from-blue-900 via-purple-900 to-indigo-900' }) => {
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
    datasets: [
      {
        data: [20, 30, 40, 30, 50],
        borderColor: 'rgba(255, 255, 255, 0.8)',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderWidth: 2,
        pointRadius: 0,
        tension: 0.4,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className={`relative p-4 rounded-md flex flex-col justify-between shadow-lg overflow-hidden ${bgColor} text-white transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-2xl h-24`}>
      {/* Background Line Chart */}
      <div className="absolute inset-0 opacity-20">
        <Line data={data} options={options} />
      </div>
      
      {/* KPI Content */}
      <div className="relative z-10 flex items-center justify-between">
        <div>
          <div className="text-sm uppercase tracking-wide font-medium mb-2 opacity-90">{title}</div>
          <div className="text-3xl font-extrabold">{value}</div>
        </div>

        {/* Icon with Neon Effect */}
        <div className="relative z-10">
          <div className="p-2 bg-gradient-to-r from-yellow-400 via-red-400 to-pink-500 rounded-full shadow-lg">
            <FontAwesomeIcon icon={icon} className="text-2xl text-white neon-effect" />
          </div>
        </div>
      </div>
      
      {/* Cool Border Effect */}
      <div className="absolute inset-0 border-2 border-transparent rounded-xl hover:border-yellow-400 transition duration-300"></div>
    </div>
  );
};

export default OKPI;
