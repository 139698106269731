import React, { useEffect, useState } from 'react';
import KPI from './KPI'; // Import the KPI component

const VucaAlnahyanright = () => {
  const [data, setData] = useState({
    temperature: '0°C',
    totalVehicles: '0',
    trafficDensity: '0%',
    totalViolations: '0',
    estimatedFine: '0',
  });

  useEffect(() => {
    document.body.style.backgroundColor = 'transparent';
    document.getElementById('root').style.backgroundColor = 'transparent';
    // Alnahyan-specific data based on CSV information
    const alnahyanData = {
        totalVehicles: '298',  // Total vehicles in the area
        totalViolations: '52', // Count of violations where "is_viol" is "1"
        trafficDensity: '17.45%', // Percentage of violations (52 out of 298)
        estimatedFine: '40,800', // Total fines for violations
        temperature: '33.4°C', // Average temperature
      };
      

    // Set the Alnahyan-specific data
    setData({
      temperature: alnahyanData.temperature,
      totalVehicles: alnahyanData.totalVehicles,
      trafficDensity: alnahyanData.trafficDensity,
      dailyViolations: alnahyanData.dailyViolations,
      estimatedFine: alnahyanData.estimatedFine,
    });
  }, []);

  return (
    <div className="h-screen flex flex-col justify-center items-end pr-12 bg-transparent">
      <h2 style={{ color: 'white', marginBottom: '20px' }}>Alnahyan</h2>
      <KPI icon="fas fa-thermometer-half" value={data.temperature} label="Temperature" valueColor="#FF4500" />
      <KPI icon="fas fa-car" value={data.totalVehicles} label="Total Vehicles" valueColor="#ccc" />
      <KPI icon="fas fa-road" value={data.trafficDensity} label="Traffic Density" valueColor="#28b16f" />
      <KPI icon="fas fa-exclamation-triangle" value={data.totalViolations} label="Total Violations" valueColor="#FFA500" />
      <KPI icon="fas fa-money-bill" value={data.estimatedFine} label="Estimated Fine" valueColor="#ccc" />
    </div>
  );
};

export default VucaAlnahyanright;
