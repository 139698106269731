import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const ViolationDonutChartDubai2 = ({ darkMode }) => {
  const [violationData, setViolationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const jsonFilePath = process.env.PUBLIC_URL + '/data summary.json';

    fetch(jsonFilePath)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((jsonData) => {
        setViolationData(jsonData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error loading the JSON file:', error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  const getViolationPercentage = (data) => {
    const totalEntry = data.find(item => item.area === "Dubai2" && item.month === "Total");
    if (totalEntry) {
      const totalViolations = totalEntry.total_violations || 0;
      const totalDataPoints = totalEntry.total_data_points || 1; // Prevent division by zero
      return (totalViolations / totalDataPoints) * 100;
    }
    return 0;
  };

  const violationPercentage = getViolationPercentage(violationData);

  const series = [violationPercentage, 100 - violationPercentage];
  const labels = ['Violations', 'Non-Violations'];

  const options = {
    chart: {
      type: 'donut',
      background: 'transparent',
      foreColor: darkMode ? '#fff' : '#000',
    },
    colors: ['#48BB78', '#E2E8F0'], // Green for violations, light gray for non-violations
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(2)}%`, // Display percentage
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: darkMode ? '#fff' : '#000',
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      labels: {
        colors: darkMode ? '#fff' : '#000',
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
        },
      },
    },
    labels: labels,
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <div className={`p-4 rounded-lg ${darkMode ? 'bg-gray-800/80 text-gray-200' : 'bg-white/30 text-gray-900'} relative`} style={{ width: '386px' }}>
      <div className="text-sm font-semibold mb-1 text-left" style={{ textShadow: '1px 1px 2px rgba(0,0,0,0.5)', marginBottom: '5px' }}>
        Violations Overview for Dubai2 (Total)
      </div>
      <div className="relative w-full" style={{ height: '185px' }}>
        <Chart options={options} series={series} type="donut" height="200" />
      </div>
    </div>
  );
};

export default ViolationDonutChartDubai2;
