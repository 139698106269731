import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faBell, faExclamationCircle, faUserSecret, faCheckCircle, faTimesCircle, faBan, faTable } from "@fortawesome/free-solid-svg-icons";

const DataTable = ({ data }) => {
  return (
    <div className="bg-gray-900 p-6 rounded-lg shadow-xl w-full">
      {/* Title with Icon */}
      <div className="flex items-center mb-6">
        <FontAwesomeIcon icon={faTable} className="text-2xl text-blue-400 mr-3" />
        <h3 className="text-2xl font-semibold text-white">System Data Overview</h3>
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-800 rounded-lg text-white shadow-md">
          <thead>
            <tr className="bg-gradient-to-r from-gray-800 to-gray-700 text-left">
              <th className="px-6 py-4 text-gray-300">Category</th>
              <th className="px-6 py-4 text-gray-300">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr className="hover:bg-gray-700 transition-all duration-300 ease-in-out transform hover:scale-[1.02]">
              <td className="px-6 py-4 flex items-center">
                <FontAwesomeIcon icon={faCogs} className="text-green-400 mr-3" />
                Total Systems Monitored
              </td>
              <td className="px-6 py-4">{data.totalSystemsMonitored}</td>
            </tr>
            <tr className="hover:bg-gray-700 transition-all duration-300 ease-in-out transform hover:scale-[1.02]">
              <td className="px-6 py-4 flex items-center">
                <FontAwesomeIcon icon={faBell} className="text-red-400 mr-3" />
                Active Alerts
              </td>
              <td className="px-6 py-4">{data.activeAlerts}</td>
            </tr>
            <tr className="hover:bg-gray-700 transition-all duration-300 ease-in-out transform hover:scale-[1.02]">
              <td className="px-6 py-4 flex items-center">
                <FontAwesomeIcon icon={faExclamationCircle} className="text-yellow-400 mr-3" />
                Devices Offline
              </td>
              <td className="px-6 py-4">{data.devicesOffline}</td>
            </tr>
            <tr className="hover:bg-gray-700 transition-all duration-300 ease-in-out transform hover:scale-[1.02]">
              <td className="px-6 py-4 flex items-center">
                <FontAwesomeIcon icon={faUserSecret} className="text-blue-400 mr-3" />
                Unauthorized Entries
              </td>
              <td className="px-6 py-4">{data.unauthorizedEntries}</td>
            </tr>
            <tr className="hover:bg-gray-700 transition-all duration-300 ease-in-out transform hover:scale-[1.02]">
              <td className="px-6 py-4 flex items-center">
                <FontAwesomeIcon icon={faCheckCircle} className="text-green-400 mr-3" />
                Online Systems
              </td>
              <td className="px-6 py-4">{data.onlineSystems}</td>
            </tr>
            <tr className="hover:bg-gray-700 transition-all duration-300 ease-in-out transform hover:scale-[1.02]">
              <td className="px-6 py-4 flex items-center">
                <FontAwesomeIcon icon={faTimesCircle} className="text-red-400 mr-3" />
                Offline Systems
              </td>
              <td className="px-6 py-4">{data.offlineSystems}</td>
            </tr>
            <tr className="hover:bg-gray-700 transition-all duration-300 ease-in-out transform hover:scale-[1.02]">
              <td className="px-6 py-4 flex items-center">
                <FontAwesomeIcon icon={faExclamationCircle} className="text-yellow-400 mr-3" />
                Malfunctioning Systems
              </td>
              <td className="px-6 py-4">{data.malfunctioningSystems}</td>
            </tr>
            <tr className="hover:bg-gray-700 transition-all duration-300 ease-in-out transform hover:scale-[1.02]">
              <td className="px-6 py-4 flex items-center">
                <FontAwesomeIcon icon={faBan} className="text-red-400 mr-3" />
                Panic Button Activations
              </td>
              <td className="px-6 py-4">{data.panicButtonActivations}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataTable;
