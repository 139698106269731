import React, { useEffect, useState } from 'react';
import KPI from './KPI'; // Import the KPI component

const VucaDubai2right = () => {
  const [data, setData] = useState({
    temperature: '0°C',
    totalVehicles: '0',
    trafficDensity: '0%',
    totalViolations: '0',
    estimatedFine: '0',
  });

  useEffect(() => {
    document.body.style.backgroundColor = 'transparent';
    document.getElementById('root').style.backgroundColor = 'transparent';
   
const dubai2Data = {
  totalVehicles: '49',  // Total vehicles in the area
  totalViolations: '49', // Count of violations where "is_viol" is "1"
  trafficDensity: '100%', // Based on the violation vs non-violation data
  estimatedFine: '47,250', // Total fines for violations
  temperature: '33.2°C', // Average temperature
};

    setData({
      temperature: dubai2Data.temperature,
      totalVehicles: dubai2Data.totalVehicles,
      trafficDensity: dubai2Data.trafficDensity,
      dailyViolations: dubai2Data.dailyViolations,
      estimatedFine: dubai2Data.estimatedFine,
    });
  }, []);

  return (
    <div className="h-screen flex flex-col justify-center items-end pr-12 bg-transparent">
      <h2 style={{ color: 'white', marginBottom: '20px' }}>Dubai2</h2>
      <KPI icon="fas fa-thermometer-half" value={data.temperature} label="Temperature" valueColor="#FF4500" />
      <KPI icon="fas fa-car" value={data.totalVehicles} label="Total Vehicles" valueColor="#ccc" />
      <KPI icon="fas fa-road" value={data.trafficDensity} label="Traffic Density" valueColor="#28b16f" />
      <KPI icon="fas fa-exclamation-triangle" value={data.totalViolations} label="Total Violations" valueColor="#FFA500" />
      <KPI icon="fas fa-money-bill" value={data.estimatedFine} label="Estimated Fine" valueColor="#ccc" />
    </div>
  );
};

export default VucaDubai2right;
