import React, { useEffect, useState } from 'react';
import KPI from './KPI'; // Import the KPI component

const VucaCornicheright = () => {
  const [data, setData] = useState({
    temperature: '0°C',
    totalVehicles: '0',
    trafficDensity: '0%',
    totalViolations: '0',
    estimatedFine: '0',
  });

  useEffect(() => {
    document.body.style.backgroundColor = 'transparent';
    document.getElementById('root').style.backgroundColor = 'transparent';
    // Corniche-specific data hardcoded or fetched from API
 
const cornicheData = {
    totalVehicles: '254',  // Total vehicles in the area
    totalViolations: '50', // Count of violations where "is_viol" is "1"
    trafficDensity: '19.69%', // Percentage of violations (50 out of 254)
    estimatedFine: '53,400', // Total fines for violations
    temperature: '33.5°C', // Average temperature
  };

    // Set the Corniche-specific data
    setData({
      temperature: cornicheData.temperature,
      totalVehicles: cornicheData.totalVehicles,
      trafficDensity: cornicheData.trafficDensity,
      dailyViolations: cornicheData.dailyViolations,
      estimatedFine: cornicheData.estimatedFine,
    });
  }, []);

  return (
    <div className="h-screen flex flex-col justify-center items-end pr-12 bg-transparent">
      <h2 style={{ color: 'white', marginBottom: '20px' }}>Corniche</h2>
      <KPI icon="fas fa-thermometer-half" value={data.temperature} label="Temperature" valueColor="#FF4500" />
      <KPI icon="fas fa-car" value={data.totalVehicles} label="Total Vehicles" valueColor="#ccc" />
      <KPI icon="fas fa-road" value={data.trafficDensity} label="Traffic Density" valueColor="#28b16f" />
      <KPI icon="fas fa-exclamation-triangle" value={data.totalViolationsViolations} label="Total Violations" valueColor="#FFA500" />
      <KPI icon="fas fa-money-bill" value={data.estimatedFine} label="Estimated Fine" valueColor="#ccc" />
    </div>
  );
};

export default VucaCornicheright;
