import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const SystemFailuresChart = ({ data }) => {
  if (!data || !data.systemFailures) {
    return <div className="text-white text-center">Loading system failures...</div>;
  }

  const failures = data.systemFailures.reduce(
    (acc, system) => {
      acc[system.status]++;
      return acc;
    },
    { offline: 0, malfunctioning: 0 }
  );

  const chartData = {
    series: [failures.offline, failures.malfunctioning],
    options: {
      chart: {
        type: 'donut',
        background: 'transparent',
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 1000,
        },
      },
      labels: ['Offline', 'Malfunctioning'],
      colors: ['#EF4444', '#F59E0B'],
      dataLabels: {
        style: {
          colors: ['#FFFFFF'],
          fontSize: '12px',
          fontWeight: 'bold',
        },
        dropShadow: {
          enabled: true,
          top: 1,
          left: 1,
          blur: 2,
          opacity: 0.6,
        },
        formatter: (val, opts) => `${opts.w.globals.labels[opts.seriesIndex]}: ${Math.round(val)}%`,
      },
      plotOptions: {
        pie: {
          donut: {
            size: '90%',
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '14px',
                fontWeight: 500,
                color: '#E5E7EB',
                offsetY: -10,
              },
              value: {
                show: true,
                fontSize: '18px',
                fontWeight: 600,
                color: '#FFFFFF',
                formatter: (val) => `${val}`,
              },
            },
          },
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          colors: '#E5E7EB',
          fontSize: '12px',
        },
      },
      tooltip: {
        theme: 'dark',
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            chart: {
              width: '100%',
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };

  return (
    <div className="rounded-lg p-0 h-full flex flex-col" style={{ background: 'transparent' }}>
      <div className="flex-grow">
        <div className="flex items-center mb-2">
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-yellow-500 mr-2" />
          <h2 className="text-white text-lg">{`System Failures`}</h2>
        </div>
        <ReactApexChart options={chartData.options} series={chartData.series} type="donut" height="100%" />
      </div>
    </div>
  );
};

export default SystemFailuresChart;
