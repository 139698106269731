import React from 'react';

const IoTDataSummaryTable = () => {
  // Fake data for the summary table
  const data = [
    { month: "Jan", powerSupplyFailures: 1, cybersecurityIncidents: 5, backupFailures: 2 },
    { month: "Feb", powerSupplyFailures: 0, cybersecurityIncidents: 2, backupFailures: 1 },
    { month: "Mar", powerSupplyFailures: 3, cybersecurityIncidents: 4, backupFailures: 0 },
    { month: "Apr", powerSupplyFailures: 2, cybersecurityIncidents: 7, backupFailures: 3 },
    { month: "May", powerSupplyFailures: 4, cybersecurityIncidents: 1, backupFailures: 2 },
    { month: "Jun", powerSupplyFailures: 1, cybersecurityIncidents: 3, backupFailures: 0 },
    { month: "Jul", powerSupplyFailures: 0, cybersecurityIncidents: 6, backupFailures: 1 },
    { month: "Aug", powerSupplyFailures: 5, cybersecurityIncidents: 8, backupFailures: 4 },
    { month: "Sep", powerSupplyFailures: 3, cybersecurityIncidents: 4, backupFailures: 2 },
    { month: "Oct", powerSupplyFailures: 1, cybersecurityIncidents: 5, backupFailures: 1 },
    { month: "Nov", powerSupplyFailures: 2, cybersecurityIncidents: 2, backupFailures: 3 },
    { month: "Dec", powerSupplyFailures: 4, cybersecurityIncidents: 3, backupFailures: 0 },
  ];

  // Function to calculate min, max, and avg
  const calculateSummary = (key) => {
    const values = data.map(item => item[key]);
    const min = Math.min(...values);
    const max = Math.max(...values);
    const avg = (values.reduce((acc, val) => acc + val, 0) / values.length).toFixed(2);

    const minMonth = data.find(item => item[key] === min).month;
    const maxMonth = data.find(item => item[key] === max).month;

    return { min, max, avg, minMonth, maxMonth };
  };

  const powerSupplySummary = calculateSummary('powerSupplyFailures');
  const cybersecuritySummary = calculateSummary('cybersecurityIncidents');
  const backupSummary = calculateSummary('backupFailures');

  return (
    <div className="bg-gray-800 rounded-xl p-4 shadow-lg">
      <h2 className="text-xl font-bold mb-4 text-white">IoT Data Summary</h2>
      <table className="min-w-full bg-gray-700 rounded-lg overflow-hidden">
        <thead>
          <tr className="bg-gray-600 text-white">
            <th className="py-2 px-4">Metric</th>
            <th className="py-2 px-4">Min (Month)</th>
            <th className="py-2 px-4">Max (Month)</th>
            <th className="py-2 px-4">Avg</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b border-gray-500">
            <td className="py-2 px-4 text-white">Power Supply Failures</td>
            <td className="py-2 px-4 text-white bg-gradient-to-r from-green-400 to-green-600">{powerSupplySummary.min} ({powerSupplySummary.minMonth})</td>
            <td className="py-2 px-4 text-white bg-gradient-to-r from-red-400 to-red-600">{powerSupplySummary.max} ({powerSupplySummary.maxMonth})</td>
            <td className="py-2 px-4 text-white bg-blue-600">{powerSupplySummary.avg}</td>
          </tr>
          <tr className="border-b border-gray-500">
            <td className="py-2 px-4 text-white">Cybersecurity Incidents</td>
            <td className="py-2 px-4 text-white bg-gradient-to-r from-green-400 to-green-600">{cybersecuritySummary.min} ({cybersecuritySummary.minMonth})</td>
            <td className="py-2 px-4 text-white bg-gradient-to-r from-red-400 to-red-600">{cybersecuritySummary.max} ({cybersecuritySummary.maxMonth})</td>
            <td className="py-2 px-4 text-white bg-blue-600">{cybersecuritySummary.avg}</td>
          </tr>
          <tr className="border-b border-gray-500">
            <td className="py-2 px-4 text-white">Backup Failures</td>
            <td className="py-2 px-4 text-white bg-gradient-to-r from-green-400 to-green-600">{backupSummary.min} ({backupSummary.minMonth})</td>
            <td className="py-2 px-4 text-white bg-gradient-to-r from-red-400 to-red-600">{backupSummary.max} ({backupSummary.maxMonth})</td>
            <td className="py-2 px-4 text-white bg-blue-600">{backupSummary.avg}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default IoTDataSummaryTable;
