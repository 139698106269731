import React, { useEffect, useState } from 'react';
import V2KPI from '../V2kpi';

const VucaDubairightbarv2 = () => {
  const [data, setData] = useState({
    temperature: '0°C',
    totalVehicles: '0',
    trafficDensity: '0%',
    totalViolations: '0',
    estimatedFine: '0',
  });

  useEffect(() => {
    
    document.body.style.backgroundColor = 'transparent';
    document.getElementById('root').style.backgroundColor = 'transparent';

    const jsonFilePath = process.env.PUBLIC_URL + '/data summary.json';

    fetch(jsonFilePath)
      .then((response) => response.json())
      .then((jsonData) => {
        const totalVehicles = jsonData.reduce((sum, item) => sum + item.total_data_points, 0);
        const totalViolations = jsonData.reduce((sum, item) => sum + item.total_violations, 0);
        const totalPenalty = jsonData.reduce((sum, item) => sum + item.total_penalty, 0);
        const avgTemperature = (jsonData.reduce((sum, item) => sum + item.average_temperature, 0) / jsonData.length).toFixed(1);

        setData({
          temperature: `${avgTemperature}°C`,
          totalVehicles: totalVehicles.toLocaleString(),
          trafficDensity: `${((totalViolations / totalVehicles) * 100).toFixed(2)}%`,
          totalViolations: totalViolations.toLocaleString(),
          estimatedFine: totalPenalty.toLocaleString(),
        });
      })
      .catch((error) => console.error('Error loading the JSON file:', error));

    return () => {
      document.body.style.backgroundColor = null;
      document.getElementById('root').style.backgroundColor = null;
    };
  }, []);

  return (
<div className="flex flex-col justify-start items-end pr-12 bg-transparent" style={{ marginTop: '90px', height: '100%', overflowY: 'hidden' }}>
<V2KPI icon="fas fa-thermometer-half" value='33.24' label="Temperature" valueColor="#FF4500" />
      <V2KPI icon="fas fa-car" value='139,505' label="Total Unique Vehicles" valueColor="#ccc" />
      <V2KPI icon="fas fa-road" value='27.22' label="Traffic Density" valueColor="#28b16f" />
      <V2KPI icon="fas fa-exclamation-triangle" value='33,552' label="Total Violations" valueColor="#FFA500" />
      <V2KPI icon="fas fa-money-bill" value='241.25' label="AVG Estimated Fine" valueColor="#ccc" />
    </div>
  );
};

export default VucaDubairightbarv2;
