import React from 'react';

const KPI = ({ icon, value, label, valueColor }) => {
  return (
    <div
      style={{
        display: 'flex',
        marginBottom: '10px', 
        padding: '5px 0',
        backgroundColor: 'rgba(255, 255, 255, 0)', 
        width: '250px',
        gap:'20px'
      }}
    >
      <div style={{ flex: 1, textAlign: 'right', maxWidth: '75%'   ,alignItems:'flex-end', }}>
        <div style={{ fontSize: '0.9rem', color: '#ddd', fontWeight: '500' }}>{label}</div> {/* Label */}
        <div
          style={{
            fontSize: '2.5rem', 
            fontWeight: 'bold',
            color: valueColor,
          }}
        >
          {value}
        </div>
      </div>

      <i
        className={icon}
        style={{
          
          alignSelf:'flex-end',
          width:'24px',
          fontSize: '2.5rem', 
          color: '#ccc', 
          marginBottom:'8px'
        
        }}
      ></i>
    </div>
  );
};

export default KPI;
