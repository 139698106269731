import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

// Register the Chart.js plugins
Chart.register(ChartDataLabels);

const LocationDonutChart = ({ data, darkMode, title }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    if (!data || data.length === 0) return;

    // Process alarm data by location
    const locationCount = {};
    data.forEach((alarm) => {
      const location = alarm.location || 'Unknown';
      locationCount[location] = (locationCount[location] || 0) + 1;
    });

    const locations = Object.keys(locationCount);
    const counts = Object.values(locationCount);

    setChartData({
      labels: locations,
      datasets: [
        {
          label: 'Occurrences',
          data: counts,
          backgroundColor: darkMode
            ? ['rgba(79, 70, 229, 0.8)', 'rgba(59, 130, 246, 0.8)', 'rgba(16, 185, 129, 0.8)', 'rgba(251, 191, 36, 0.8)']
            : ['rgba(167, 243, 208, 0.8)', 'rgba(253, 224, 7, 0.8)', 'rgba(253, 147, 22, 0.8)', 'rgba(239, 68, 68, 0.8)'],
          borderColor: 'transparent',
          borderWidth: 2,
          hoverOffset: 8,
        },
      ],
    });
  }, [data, darkMode]);

  // Format numbers for tooltips and datalabels
  const formatNumber = (num) => {
    return num >= 1000 ? (num / 1000).toFixed(1) + 'k' : num.toFixed(0);
  };

  return (
    <div
      className={`rounded-lg bg-gray-800 text-gray-200 relative flex flex-col mt-2`}
      style={{ width: '100%', height: '250px' }} // Increased height
    >
      {/* Chart Title with Icon */}
      <div className="flex items-center mb-2">
        <FontAwesomeIcon icon={faBell} className="text-blue-500 text-xl mr-2" />
        <h3 className="text-lg font-semibold">{title}</h3>
      </div>

      <div style={{ height: '100%', position: 'relative' }}>
        <Doughnut
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'bottom',
                labels: {
                  color: '#E5E7EB',
                  font: {
                    size: 10,
                  },
                },
              },
              tooltip: {
                callbacks: {
                  label: function (tooltipItem) {
                    const value = tooltipItem.raw;
                    return `${tooltipItem.label}: ${formatNumber(value)}`;
                  },
                },
                backgroundColor: 'rgba(31, 41, 55, 0.9)',
                titleColor: '#E5E7EB',
                bodyColor: '#E5E7EB',
                borderColor: '#374151',
                borderWidth: 1,
              },
              datalabels: {
                color: '#E5E7EB',
                font: {
                  size: 12,
                  weight: 'bold',
                },
                formatter: (value) => `${formatNumber(value)}`,
                anchor: 'end',
                align: 'start',
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default LocationDonutChart;
