import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield } from '@fortawesome/free-solid-svg-icons';

// Register the Chart.js plugins
Chart.register(ChartDataLabels);

const AgentCapabilityDonutChart = ({ agents }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    if (!agents || agents.length === 0) return;

    // Process agent capability data by category
    const categoryCount = { 'category1': 0, 'category2': 0, 'category3': 0 };
    agents.forEach((agent) => {
      const category = agent.category || 'Unknown';
      if (categoryCount[category] !== undefined) {
        categoryCount[category] += 1;
      }
    });

    const categories = Object.keys(categoryCount);
    const counts = Object.values(categoryCount);

    setChartData({
      labels: categories,
      datasets: [
        {
          label: 'Agents',
          data: counts,
          backgroundColor: [
            '#3B82F6', // Blue 500
            '#60A5FA', // Blue 400
            '#93C5FD', // Blue 300
          ],
          borderColor: '#E5E7EB',
          borderWidth: 3,
          hoverOffset: 15,
        },
      ],
    });
  }, [agents]);

  // Format numbers for tooltips and datalabels
  const formatNumber = (num) => {
    return num >= 1000 ? (num / 1000).toFixed(1) + 'k' : num.toFixed(0);
  };

  return (
    <div className="p-2 rounded-lg bg-gray-800 text-white h-72 shadow-md">
      <h3 className="text-lg font-semibold mb-4 flex items-center text-white">
        <FontAwesomeIcon icon={faUserShield} className="mr-2 text-blue-400" />
        Agent Capability Distribution
      </h3>

      <div style={{ height: 'calc(100% - 40px)' }}>
        <Doughnut
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'bottom',
                labels: {
                  color: '#E5E7EB',
                  font: {
                    size: 12,
                  },
                },
              },
              tooltip: {
                callbacks: {
                  label: function (tooltipItem) {
                    const value = tooltipItem.raw;
                    return `${tooltipItem.label}: ${formatNumber(value)}`;
                  },
                },
                backgroundColor: '#374151',
                titleColor: '#E5E7EB',
                bodyColor: '#E5E7EB',
                borderColor: '#E5E7EB',
                borderWidth: 1,
              },
              datalabels: {
                color: '#E5E7EB',
                font: {
                  size: 12,
                  weight: 'bold',
                },
                formatter: (value) => `${formatNumber(value)}`,
                anchor: 'end',
                align: 'start',
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default AgentCapabilityDonutChart;
