import React from 'react';
import { Bar } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faExclamationCircle, faFile } from '@fortawesome/free-solid-svg-icons';

const IncidentReports = () => {
  // Fake data for demonstration
  const totalIncidents = 15;
  const healthIncidents = 8;
  const safetyIncidents = 7;

  const recentIncidents = [
    { id: 1, type: 'Health', description: 'Minor injury reported', date: '2024-10-20' },
    { id: 2, type: 'Safety', description: 'Near-miss incident', date: '2024-10-18' },
    { id: 3, type: 'Health', description: 'Fatigue reported', date: '2024-10-16' },
    { id: 4, type: 'Safety', description: 'Equipment malfunction', date: '2024-10-15' },
    { id: 5, type: 'Health', description: 'Heat stress incident', date: '2024-10-12' },
  ];

  // Data for the chart
  const incidentData = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
    datasets: [
      {
        label: 'Total Incidents',
        data: [3, 5, 4, 3],
        backgroundColor: 'rgba(255, 99, 132, 0.7)',
      },
      {
        label: 'Health Incidents',
        data: [2, 2, 3, 1],
        backgroundColor: 'rgba(54, 162, 235, 0.7)',
      },
      {
        label: 'Safety Incidents',
        data: [1, 3, 1, 2],
        backgroundColor: 'rgba(255, 206, 86, 0.7)',
      },
    ],
  };

  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg mt-6">
      <h2 className="text-xl text-white font-bold mb-4 flex items-center">
        <FontAwesomeIcon icon={faFile} className="mr-2" />
        Incident Reports
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
        <div className="flex flex-col items-center bg-blue-900 p-4 rounded-lg shadow-md">
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-3xl text-red-400 mb-2" />
          <h3 className="text-lg text-white">Total Incidents</h3>
          <p className="text-3xl text-white font-bold">{totalIncidents}</p>
        </div>
        <div className="flex flex-col items-center bg-green-900 p-4 rounded-lg shadow-md">
          <FontAwesomeIcon icon={faExclamationCircle} className="text-3xl text-yellow-400 mb-2" />
          <h3 className="text-lg text-white">Health Incidents</h3>
          <p className="text-3xl text-white font-bold">{healthIncidents}</p>
        </div>
        <div className="flex flex-col items-center bg-orange-900 p-4 rounded-lg shadow-md">
          <FontAwesomeIcon icon={faExclamationCircle} className="text-3xl text-blue-400 mb-2" />
          <h3 className="text-lg text-white">Safety Incidents</h3>
          <p className="text-3xl text-white font-bold">{safetyIncidents}</p>
        </div>
      </div>

      <h3 className="text-lg text-white mb-2">Incident Trend</h3>
      <div className="mb-4">
        <Bar
          data={incidentData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                labels: {
                  color: 'white',
                },
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                grid: {
                  color: 'rgba(255, 255, 255, 0.1)',
                },
                ticks: {
                  color: 'white',
                },
              },
              x: {
                grid: {
                  color: 'rgba(255, 255, 255, 0.1)',
                },
                ticks: {
                  color: 'white',
                },
              },
            },
          }}
          height={200}
        />
      </div>

      <h3 className="text-lg text-white mb-2">Recent Incidents</h3>
      <table className="min-w-full bg-gray-700 text-white rounded-lg overflow-hidden">
        <thead>
          <tr className="bg-gray-600">
            <th className="py-2 px-4">Type</th>
            <th className="py-2 px-4">Description</th>
            <th className="py-2 px-4">Date</th>
          </tr>
        </thead>
        <tbody>
          {recentIncidents.map(incident => (
            <tr key={incident.id} className="border-b border-gray-500 hover:bg-gray-600 transition-colors duration-300">
              <td className="py-2 px-4">{incident.type}</td>
              <td className="py-2 px-4">{incident.description}</td>
              <td className="py-2 px-4">{incident.date}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IncidentReports;
