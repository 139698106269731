import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const VucaButtombar = () => {
  const [vehicleDistribution, setVehicleDistribution] = useState(null);
  const [violationDistribution, setViolationDistribution] = useState(null);
  const [gaugeData, setGaugeData] = useState([]);

  useEffect(() => {
    document.body.style.backgroundColor = 'transparent';
    document.getElementById('root').style.backgroundColor = 'transparent';

    const calculateData = () => {
      const areas = [
        { name: "Almuteena", motorcycles: 19.0, cars: 52.4, trucks: 15.9, heavyTrucks: 12.7, penalty: 772.2, temperature: 33.2 },
        { name: "Alnahyan", motorcycles: 24.2, cars: 53.7, trucks: 10.7, heavyTrucks: 11.4, penalty: 136.9, temperature: 33.2 },
        { name: "Dubai2", motorcycles: 28.6, cars: 46.9, trucks: 8.2, heavyTrucks: 16.3, penalty: 964.3, temperature: 33.2 },
        { name: "Albateen", motorcycles: 23.7, cars: 55.2, trucks: 11.4, heavyTrucks: 9.8, penalty: 150.0, temperature: 33.3 },
        { name: "Corniche", motorcycles: 19.3, cars: 58.7, trucks: 14.6, heavyTrucks: 7.5, penalty: 210.2, temperature: 33.3 },
        { name: "Downtown", motorcycles: 19.2, cars: 59.9, trucks: 9.8, heavyTrucks: 11.1, penalty: 321.2, temperature: 33.3 },
        { name: "E20", motorcycles: 20.7, cars: 53.8, trucks: 14.7, heavyTrucks: 10.9, penalty: 150.0, temperature: 33.3 },
        { name: "Khalifa", motorcycles: 23.5, cars: 53.7, trucks: 11.0, heavyTrucks: 11.7, penalty: 187.0, temperature: 33.3 }
      ];

      // Calculate overall values for gauges
      const totalVehicles = areas.reduce((acc, area) => acc + (area.motorcycles + area.cars + area.trucks + area.heavyTrucks), 0);
      const avgPenalty = (areas.reduce((acc, area) => acc + area.penalty, 0) / areas.length).toFixed(2);
      const avgTemperature = (areas.reduce((acc, area) => acc + area.temperature, 0) / areas.length).toFixed(2);
      const highestSpeedingArea = "Dubai2"; // Example for highest speeding area

      const gaugeData = [
        { name: "Total Vehicles", value: totalVehicles, max: 1000 },
        { name: "Avg Penalty (€)", value: avgPenalty, max: 1000 },
        { name: "Avg Temperature (°C)", value: avgTemperature, max: 50 },
        { name: "Highest Speeding Area", value: highestSpeedingArea, isText: true }, // Text value, not a gauge
      ];

      setVehicleDistribution({
        series: [
          { name: "Motorcycles", data: areas.map(area => area.motorcycles) },
          { name: "Cars", data: areas.map(area => area.cars) },
          { name: "Trucks", data: areas.map(area => area.trucks) },
          { name: "Heavy Trucks", data: areas.map(area => area.heavyTrucks) }
        ],
        options: {
          chart: { type: 'bar', background: 'transparent', toolbar: { show: false } },
          xaxis: { categories: areas.map(area => area.name), labels: { style: { colors: '#E0E0E0', fontSize: '12px' } } },
          yaxis: { labels: { style: { colors: '#E0E0E0', fontSize: '12px' } } },
          title: { text: "Vehicle Distribution by Type", style: { color: '#E0E0E0', fontSize: '16px' } },
          grid: { show: false },
          theme: { mode: 'dark' },
          tooltip: { theme: 'dark' },
        }
      });

      setViolationDistribution({
        series: [
          { name: "Speeding", data: [41.3, 48.1, 57.1, 54.2, 48.0, 41.2, 47.8, 43.4] },
          { name: "Illegal Parking", data: [27.0, 23.1, 22.4, 11.9, 26.0, 17.6, 15.2, 24.5] },
          { name: "Lane Violation", data: [15.9, 11.5, 10.2, 16.9, 4.0, 25.5, 13.0, 15.1] }
        ],
        options: {
          chart: { type: 'line', background: 'transparent', toolbar: { show: false } },
          xaxis: { categories: areas.map(area => area.name), labels: { style: { colors: '#E0E0E0', fontSize: '12px' } } },
          yaxis: { labels: { style: { colors: '#E0E0E0', fontSize: '12px' } } },
          title: { text: "Violation Distribution by Type", style: { color: '#E0E0E0', fontSize: '16px' } },
          grid: { borderColor: '#444' },
          theme: { mode: 'dark' },
          tooltip: { theme: 'dark' }
        }
      });

      setGaugeData(gaugeData);
    };

    calculateData();
  }, []);

  return (
    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex flex-col items-center bg-transparent rounded-lg w-3/4 p-4 text-white">

      {/* Gauge Charts Section */}
      <div className="flex justify-between w-full mb-4 bg-transparent">
        {gaugeData.slice(0, 3).map((gauge, index) => (  // Only use the first 3 gauges for the charts
          <div 
            key={index} 
            className="bg-transparent p-2 shadow-md rounded-lg w-1/4" 
            style={{ backgroundColor: 'transparent' }}
          >
            <Chart 
              options={{
                chart: {
                  type: 'radialBar',
                  offsetY: -20,
                  sparkline: { enabled: true },
                  background: 'transparent', 
                },
                plotOptions: {
                  radialBar: {
                    startAngle: -150,
                    endAngle: 150,
                    hollow: {
                      margin: 0,
                      size: '70%',
                      background: 'transparent',
                    },
                    dataLabels: {
                      name: {
                        show: true,
                        color: '#fff',
                        fontSize: '14px',
                      },
                      value: {
                        formatter: () => gauge.value,
                        color: '#fff',
                        fontSize: '20px',
                        show: true,
                      },
                    },
                  },
                },
                fill: {
                  colors: ['#00E1FF'], 
                },
                stroke: {
                  lineCap: 'round',
                },
                labels: [gauge.name],
                theme: { mode: 'dark' },
              }}
              series={[gauge.value]}
              type="radialBar"
              height={100} // Adjusted smaller height for the gauge
            />
          </div>
        ))}
        {/* Only render "Highest Speeding Area" if gaugeData[3] exists */}
        {gaugeData[3] && (
          <div className="bg-transparent p-2 shadow-md rounded-lg w-1/4 flex items-center justify-center">
            <div className="text-white text-lg">
              <span>{`Highest Speeding Area: ${gaugeData[3].value}`}</span>
            </div>
          </div>
        )}
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-2 gap-4 w-full">
        <div className="bg-transparent p-4 shadow-md rounded-lg border border-blue-500 hover:border-blue-400 transition-colors duration-300">
          {vehicleDistribution && (
            <Chart options={vehicleDistribution.options} series={vehicleDistribution.series} type="bar" height={150} />
          )}
        </div>
        <div className="bg-transparent p-4 shadow-md rounded-lg border border-purple-500 hover:border-purple-400 transition-colors duration-300">
          {violationDistribution && (
            <Chart options={violationDistribution.options} series={violationDistribution.series} type="line" height={150} />
          )}
        </div>
      </div>
    </div>
  );
};

export default VucaButtombar;
