import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';

// Register necessary components for Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

// Sample fake data for HVAC system failures
const hvacFailuresData = [
  { month: "Jan", failures: 2 },
  { month: "Feb", failures: 3 },
  { month: "Mar", failures: 4 },
  { month: "Apr", failures: 5 },
  { month: "May", failures: 6 },
  { month: "Jun", failures: 3 },
  { month: "Jul", failures: 7 },
  { month: "Aug", failures: 2 },
  { month: "Sep", failures: 4 },
  { month: "Oct", failures: 5 },
  { month: "Nov", failures: 0 },
  { month: "Dec", failures: 3 },
];

const HVACFailuresChart = () => {
  // Prepare data for the chart
  const chartData = {
    labels: hvacFailuresData.map(item => item.month),
    datasets: [
      {
        label: 'HVAC System Failures',
        data: hvacFailuresData.map(item => item.failures),
        backgroundColor: 'rgba(0, 123, 255, 0.6)', // Blue color with transparency
        borderColor: 'rgba(0, 123, 255, 1)', // Blue color for border
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Number of Failures',
        },
        min: 0,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw;
            return `Failures: ${value}`;
          },
        },
      },
    },
  };

  return (
    <div className="w-full h-64">
      <h2 className="text-lg font-bold text-white mb-2 text-left">HVAC System Failures (Monthly)</h2>
      <Bar
        data={chartData}
        options={options}
      />
    </div>
  );
};

export default HVACFailuresChart;
