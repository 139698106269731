import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { FaDatabase } from 'react-icons/fa';

const BackupDataRecoveryFailures = () => {
  // Fake data for Backup and Data Recovery Failures
  const backupData = [
    { month: "Jan", failures: 2 },
    { month: "Feb", failures: 1 },
    { month: "Mar", failures: 3 },
    { month: "Apr", failures: 0 },
    { month: "May", failures: 4 },
    { month: "Jun", failures: 2 },
    { month: "Jul", failures: 1 },
    { month: "Aug", failures: 5 },
    { month: "Sep", failures: 0 },
    { month: "Oct", failures: 3 },
    { month: "Nov", failures: 2 },
    { month: "Dec", failures: 1 },
  ];

  // Prepare data for Backup Failures chart
  const backupChartData = {
    series: [
      {
        name: 'Failures',
        data: backupData.map(item => item.failures),
      },
    ],
    categories: backupData.map(item => item.month),
  };

  // Chart options for Backup Failures
  const backupOptions = {
    chart: {
      type: 'line',
      height: '100%',
      zoom: {
        enabled: false,
      },
    },
    colors: ['#FF4560'],
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    xaxis: {
      categories: backupChartData.categories,
      title: {
        text: 'Month',
        style: {
          color: '#FFFFFF',
        },
      },
      labels: {
        style: {
          colors: '#FFFFFF', // Ensure x-axis labels are white
        },
      },
    },
    yaxis: {
      title: {
        text: 'Number of Failures',
        style: {
          color: '#FFFFFF',
        },
      },
      min: 0,
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      floating: true,
      offsetY: 0,
      labels: {
        colors: '#FFFFFF',
      },
    },
  };

  return (
    <div className="bg-gray-800 rounded-xl p-4 h-full mb-4">
      <h2 className="text-xl font-extrabold mb-2 flex items-center text-white">
        <FaDatabase className="mr-2 text-blue-300" /> Backup and Data Recovery Failures
      </h2>
      <div className="h-60"> {/* Adjusted height for better fit */}
        <ReactApexChart options={backupOptions} series={backupChartData.series} type="line" height="100%" />
      </div>
    </div>
  );
};

export default BackupDataRecoveryFailures;
