import React from 'react';

const ParkingSummaryTable = () => {
  // Example parking data
  const parkingData = [
    { month: "Jan", alerts: 2, carsEntered: 18, overstays: 1 },
    { month: "Feb", alerts: 3, carsEntered: 22, overstays: 4 },
    { month: "Mar", alerts: 4, carsEntered: 15, overstays: 3 },
    { month: "Apr", alerts: 5, carsEntered: 28, overstays: 6 },
    { month: "May", alerts: 7, carsEntered: 35, overstays: 2 },
    { month: "Jun", alerts: 6, carsEntered: 30, overstays: 5 },
    { month: "Jul", alerts: 8, carsEntered: 40, overstays: 7 },
    { month: "Aug", alerts: 5, carsEntered: 34, overstays: 3 },
    { month: "Sep", alerts: 4, carsEntered: 30, overstays: 4 },
    { month: "Oct", alerts: 2, carsEntered: 25, overstays: 5 },
    { month: "Nov", alerts: 3, carsEntered: 20, overstays: 2 },
    { month: "Dec", alerts: 5, carsEntered: 18, overstays: 6 },
  ];

  // Function to calculate min, max, and avg
  const calculateSummary = (key) => {
    const values = parkingData.map(item => item[key]);
    const min = Math.min(...values);
    const max = Math.max(...values);
    const avg = (values.reduce((acc, val) => acc + val, 0) / values.length).toFixed(2);

    const minMonth = parkingData.find(item => item[key] === min).month;
    const maxMonth = parkingData.find(item => item[key] === max).month;

    return { min, max, avg, minMonth, maxMonth };
  };

  const alertsSummary = calculateSummary('alerts');
  const carsEnteredSummary = calculateSummary('carsEntered');
  const overstaysSummary = calculateSummary('overstays');

  return (
    <div className="bg-gray-800 rounded-xl p-4 shadow-lg">
      <h2 className="text-xl font-bold mb-4 text-white">Parking Data Summary</h2>
      <table className="min-w-full bg-gray-700 rounded-lg overflow-hidden">
        <thead>
          <tr className="bg-gray-600 text-white">
            <th className="py-2 px-4">Metric</th>
            <th className="py-2 px-4">Min (Month)</th>
            <th className="py-2 px-4">Max (Month)</th>
            <th className="py-2 px-4">Avg</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b border-gray-500">
            <td className="py-2 px-4 text-white">Parking Alerts</td>
            <td className="py-2 px-4 text-white bg-gradient-to-r from-green-400 to-green-600">{alertsSummary.min} ({alertsSummary.minMonth})</td>
            <td className="py-2 px-4 text-white bg-gradient-to-r from-red-400 to-red-600">{alertsSummary.max} ({alertsSummary.maxMonth})</td>
            <td className="py-2 px-4 text-white bg-blue-600">{alertsSummary.avg}</td>
          </tr>
          <tr className="border-b border-gray-500">
            <td className="py-2 px-4 text-white">Cars Entered</td>
            <td className="py-2 px-4 text-white bg-gradient-to-r from-green-400 to-green-600">{carsEnteredSummary.min} ({carsEnteredSummary.minMonth})</td>
            <td className="py-2 px-4 text-white bg-gradient-to-r from-red-400 to-red-600">{carsEnteredSummary.max} ({carsEnteredSummary.maxMonth})</td>
            <td className="py-2 px-4 text-white bg-blue-600">{carsEnteredSummary.avg}</td>
          </tr>
          <tr className="border-b border-gray-500">
            <td className="py-2 px-4 text-white">Overstays</td>
            <td className="py-2 px-4 text-white bg-gradient-to-r from-green-400 to-green-600">{overstaysSummary.min} ({overstaysSummary.minMonth})</td>
            <td className="py-2 px-4 text-white bg-gradient-to-r from-red-400 to-red-600">{overstaysSummary.max} ({overstaysSummary.maxMonth})</td>
            <td className="py-2 px-4 text-white bg-blue-600">{overstaysSummary.avg}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ParkingSummaryTable;
