import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { FaServer, FaExclamationTriangle } from 'react-icons/fa';

const ServerOverloadFailureChart = () => {
  // Fake monthly data for Server Overload or Failure
  const data = [
    { month: "Jan", cpuAlerts: 3, memoryAlerts: 1 },
    { month: "Feb", cpuAlerts: 2, memoryAlerts: 2 },
    { month: "Mar", cpuAlerts: 5, memoryAlerts: 3 },
    { month: "Apr", cpuAlerts: 4, memoryAlerts: 0 },
    { month: "May", cpuAlerts: 6, memoryAlerts: 5 },
    { month: "Jun", cpuAlerts: 2, memoryAlerts: 3 },
    { month: "Jul", cpuAlerts: 7, memoryAlerts: 1 },
    { month: "Aug", cpuAlerts: 3, memoryAlerts: 4 },
    { month: "Sep", cpuAlerts: 4, memoryAlerts: 2 },
    { month: "Oct", cpuAlerts: 1, memoryAlerts: 0 },
    { month: "Nov", cpuAlerts: 5, memoryAlerts: 3 },
    { month: "Dec", cpuAlerts: 6, memoryAlerts: 4 },
  ];

  // Prepare data for the chart
  const chartData = {
    series: [
      {
        name: 'CPU Alerts',
        data: data.map(item => item.cpuAlerts),
      },
      {
        name: 'Memory Alerts',
        data: data.map(item => item.memoryAlerts),
      },
    ],
    categories: data.map(item => item.month),
  };

  // Chart options
  const options = {
    chart: {
      type: 'line',
      height: '100%', // Make it responsive to the container height
      zoom: {
        enabled: false,
      },
    },
    colors: ['#FF4560', '#00E396'],
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    xaxis: {
      categories: chartData.categories,
      title: {
        text: 'Month',
        style: {
          color: '#FFFFFF',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Number of Alerts',
        style: {
          color: '#FFFFFF',
        },
      },
      min: 0,
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: {
      position: 'top', // Position the legend at the top
      horizontalAlign: 'center', // Center align the legend
      floating: true, // Allows the legend to float above the chart
      offsetY: 0, // Adjust vertical position
      labels: {
        colors: '#FFFFFF', // Change legend text color
      },
    },
  };

  return (
    <div className="bg-gray-800 rounded-xl p-4 h-full">
      <h2 className="text-xl font-extrabold mb-2 flex items-center text-white">
        <FaServer className="mr-2 text-blue-300" /> Server Overload Alerts
      </h2>
      <div className="h-64"> {/* Set a specific height for the chart container */}
        <ReactApexChart options={options} series={chartData.series} type="line" height="100%" />
      </div>
    </div>
  );
};

export default ServerOverloadFailureChart;
