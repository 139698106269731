import React, { useEffect, useState } from 'react';
import { FaCar, FaChartBar, FaExclamationCircle, FaThermometerHalf } from 'react-icons/fa'; 
import ViolationTimePeriodsChartParis from './ViolationTimePeriodsChartParis ';
import ViolationDonutChartParis from './ViolationDonutChartParis';


// KPIComponent as part of the parent
const KPIComponent = ({ title, value, darkMode }) => {
    const getIcon = (title) => {
        switch (title) {
            case "Number of 2 wheels":
                return <FaChartBar className="text-blue-500 text-xl" />;
            case "Number of 4 wheels":
                return <FaCar className="text-green-500 text-xl" />;
            case "Avg Temperature":
                return <FaThermometerHalf className="text-yellow-500 text-xl" />;
            case "Avg Penalty Price":
                return <span className="text-red-500 text-xl">💵</span>;
            default:
                return null;
        }
    };

    return (
        <div className={`flex items-center p-4 rounded-lg shadow-md ${darkMode ? 'bg-gray-800/80 text-white' : 'bg-white/20 text-black'}`}>
            <div className="flex-shrink-0 mr-3 mb-6">{getIcon(title)}</div>
            <div className="flex flex-col">
            <div className="text-sm font-semibold text-white">{title}</div> {/* Text color set to white */}
            <div className="text-2xl font-bold text-white">{value}</div> {/* Text color set to white */}
            </div>
        </div>
    );
};

const VucaParisBottomBarV2 = ({ darkMode }) => {
    const [kpiData, setKpiData] = useState({
        totalVehicles: 0,
        avgPenalty: 0,
        avgTemperature: 0,
    });

    useEffect(() => {
        // Simulated data for Paris
        const totalVehicles = 10234; // Fake data
        const totalPenalties = 5432; // Fake data
        const totalTemperatures = 350; // Fake data, in degrees

        const avgPenalty = (totalPenalties / 30).toFixed(2); // Average penalty over 30 days
        const avgTemperature = (totalTemperatures / 30).toFixed(2); // Average temperature
        document.body.style.backgroundColor = 'transparent';
        document.getElementById('root').style.backgroundColor = 'transparent';

        setKpiData({
            totalVehicles,
            avgPenalty,
            avgTemperature,
        });
    }, []);

    return (
        <div className="flex flex-col items-center justify-end h-screen bg-transparent">
            {/* Container for KPIs and charts */}
            <div className="bg-black/50 w-full max-w-5xl p-2 rounded-lg flex flex-col items-center justify-center">
                {/* KPI section */}
                <div className="grid grid-cols-4 gap-2 w-full mb-2">
                    <KPIComponent title="Number of 2 wheels" value='3,500' darkMode={darkMode} />
                    <KPIComponent title="Number of 4 wheels" value='7,800' darkMode={darkMode} />
                    <KPIComponent title="Avg Temperature" value='16,5' />
                    <KPIComponent title="Avg Penalty Price" value={kpiData.avgPenalty} darkMode={darkMode} />
                </div>

                {/* Chart section with Donut chart on the right */}
                <div className="flex w-full justify-between items-start">
                    <div className="w-3/5 pr-2"> {/* Time periods chart */}
                        <ViolationTimePeriodsChartParis darkMode={darkMode} />
                    </div>
                    <div className="w-2/5 pl-4"> {/* Donut chart */}
                        <ViolationDonutChartParis darkMode={darkMode} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VucaParisBottomBarV2;
