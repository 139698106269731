import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';

// Register necessary components for Chart.js
ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

const LightingAlertsChart = () => {
  // Fake monthly data for Lighting System Alerts
  const data = [
    { month: "Jan", alerts: 2 },
    { month: "Feb", alerts: 3 },
    { month: "Mar", alerts: 6 },
    { month: "Apr", alerts: 1 },
    { month: "May", alerts: 5 },
    { month: "Jun", alerts: 7 },
    { month: "Jul", alerts: 4 },
    { month: "Aug", alerts: 8 },
    { month: "Sep", alerts: 2 },
    { month: "Oct", alerts: 6 },
    { month: "Nov", alerts: 0 },
    { month: "Dec", alerts: 3 },
  ];

  // Prepare data for the chart
  const chartData = {
    labels: data.map(item => item.month),
    datasets: [
      {
        label: 'Lighting System Alerts',
        data: data.map(item => item.alerts),
        borderColor: 'rgba(255, 165, 0, 1)', // Orange color
        backgroundColor: 'rgba(255, 165, 0, 0.2)',
        borderWidth: 2,
        fill: true,
        pointBackgroundColor: data.map(item => (item.alerts > 5 ? 'rgba(255, 0, 0, 1)' : 'rgba(255, 165, 0, 1)')),
        pointBorderColor: data.map(item => (item.alerts > 5 ? 'rgba(255, 0, 0, 1)' : 'rgba(255, 165, 0, 1)')),
        pointRadius: data.map(item => (item.alerts > 5 ? 5 : 3)),
        pointHoverRadius: data.map(item => (item.alerts > 5 ? 8 : 5)),
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Number of Alerts',
        },
        min: 0,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw;
            return `Alerts: ${value} ${value > 5 ? '⚠️' : ''}`;
          },
        },
      },
    },
  };

  return (
    <div className="w-full h-64">
      <h2 className="text-lg font-bold text-white mb-2 text-left">Lighting System Alerts (Monthly)</h2>
      <Line
        data={chartData}
        options={options}
      />
    </div>
  );
};

export default LightingAlertsChart;
