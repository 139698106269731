import React from "react";
import { Doughnut } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Register the ArcElement for the Doughnut chart
ChartJS.register(ArcElement, Tooltip, Legend);

const DeviceFailuresChart = ({ online, offline }) => {
  // Data for the doughnut chart
  const chartData = {
    labels: ['Online Devices', 'Offline Devices'],
    datasets: [
      {
        data: [online, offline],
        backgroundColor: [
          'rgba(75, 192, 192, 0.8)',  // Green for online
          'rgba(255, 99, 132, 0.8)',  // Red for offline
        ],
        hoverBackgroundColor: [
          'rgba(75, 192, 192, 1)',    // Bright green on hover
          'rgba(255, 99, 132, 1)',    // Bright red on hover
        ],
        borderWidth: 0,  // No border around the chart segments for a clean look
      },
    ],
  };

  // Chart options for a modern look
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allow it to scale within the container
    plugins: {
      legend: {
        display: true,  // Show legend for Online/Offline labels
        position: 'bottom',  // Place legend at the bottom
        labels: {
          color: '#ddd',  // Light text for the legend
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        backgroundColor: '#fff', // White background for tooltip
        titleColor: '#000', // Black text for tooltip title
        bodyColor: '#000', // Black text for tooltip body
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1,
        boxPadding: 10,
        caretPadding: 8,
        padding: 10,
      },
    },
    cutout: '70%',  // Increase the cutout size for a modern doughnut chart
  };

  return (
    <div className="relative w-full h-80 bg-gray-800 p-6 ">
      {/* Title with Icon in top left */}
    
      <div className="flex items-center mb-4">
        <FontAwesomeIcon icon={faExclamationCircle} className="text-xl text-red-500 mr-2" />
        <h3 className="text-lg font-semibold text-white">Device Failures</h3>
      </div>
      {/* Doughnut Chart */}
      
        <Doughnut data={chartData} options={options} />
      
    </div>
  );
};

export default DeviceFailuresChart;
