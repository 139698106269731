import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const ViolationDonutChart = ({ darkMode }) => {
  const [violationData, setViolationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const jsonFilePath = process.env.PUBLIC_URL + '/data summary.json';
    document.body.style.backgroundColor = 'transparent';
    document.getElementById('root').style.backgroundColor = 'transparent';

    fetch(jsonFilePath)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((jsonData) => {
        setViolationData(jsonData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error loading the JSON file:', error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  const aggregateData = (data) => {
    const result = {
      Dubai2: 0,
      Almuteena: 0,
    };

    data.forEach(item => {
      if (item.month === 9 || item.month === 10) {
        if (item.area === "Dubai2") {
          result.Dubai2 += item.total_violations;
        } else if (item.area === "Almuteena") {
          result.Almuteena += item.total_violations;
        }
      }
    });

    return result;
  };

  const aggregatedData = aggregateData(violationData);
  const series = [aggregatedData.Dubai2, aggregatedData.Almuteena];
  const labels = ['Dubai2', 'Almuteena'];

  const options = {
    chart: {
      type: 'donut',
      background: 'transparent',
      foreColor: darkMode ? '#fff' : '#000',
    },
    colors: ['#F56565', '#48BB78'], // Orange for Dubai2 and Green for Almuteena
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: darkMode ? '#fff' : '#000',
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      labels: {
        colors: darkMode ? '#fff' : '#000',
        // Fix for the correct label display
        formatter: (val, opts) => labels[opts.seriesIndex],
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
        },
      },
    },
    labels: labels, // This will show the correct labels in the chart
  };
  
  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <div className={`p-4 rounded-lg ${darkMode ? 'bg-gray-800/80 text-gray-200' : 'bg-white/30 text-gray-900'} relative`} style={{ width: '386px' }}>
      <div className="text-sm font-semibold mb-1 text-left" style={{ textShadow: '1px 1px 2px rgba(0,0,0,0.5)', marginBottom: '5px' }}>
        Violations Overview
      </div>
      <div className="relative w-full" style={{ height: '185px' }}>
        <Chart options={options} series={series} type="donut" height="200" />
      </div>
    </div>
  );
};

export default ViolationDonutChart;
