import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faFireAlt, faShieldAlt, faTrashAlt, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const AlarmTable = ({ alarms }) => {
  const [timeNow, setTimeNow] = useState(Date.now());
  const [alarmData, setAlarmData] = useState(alarms || []);
  const [filter, setFilter] = useState("All");
  const [severityFilter, setSeverityFilter] = useState("All");
  const [locationFilter, setLocationFilter] = useState("All");

  useEffect(() => {
    setAlarmData(alarms);
  }, [alarms]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeNow(Date.now());

      setAlarmData((prevAlarms) =>
        prevAlarms.map((alarm) => {
          const timeActiveInMinutes = (Date.now() - new Date(alarm.timestamp).getTime()) / 1000 / 60;
          if (alarm.severity === "Red" && timeActiveInMinutes > 5 && !alarm.acknowledged) {
            return { ...alarm, escalation: "Escalated" };
          } else if (alarm.severity === "Orange" && timeActiveInMinutes > 10 && !alarm.acknowledged) {
            return { ...alarm, escalation: "Escalated" };
          }
          return alarm;
        })
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, [alarmData]);

  const acknowledgeAlarm = (id) => {
    setAlarmData((prevAlarms) =>
      prevAlarms.map((alarm) =>
        alarm.id === id
          ? {
              ...alarm,
              acknowledged: true,
              acknowledged_time: Date.now(),
              escalation: "Acknowledged",
            }
          : alarm
      )
    );
  };

  const deleteAlarm = (id) => {
    setAlarmData((prevAlarms) => prevAlarms.filter((alarm) => alarm.id !== id));
  };

  const getActiveTime = (alarm) => {
    const startTime = alarm.acknowledged ? new Date(alarm.acknowledged_time).getTime() : new Date(alarm.timestamp).getTime();
    const secondsActive = Math.floor((Date.now() - startTime) / 1000);
    const minutes = Math.floor(secondsActive / 60);
    const seconds = secondsActive % 60;

    if (alarm.acknowledged && secondsActive < 1) {
      return "0m 0s";
    }

    return `${minutes}m ${seconds}s`;
  };

  const severityLabel = {
    Red: "Critical",
    Orange: "Major",
    Yellow: "Minor",
    Green: "Cleared",
  };

  // Filtered alarm data based on selected filters
  const filteredAlarms = alarmData.filter((alarm) => {
    const matchesStatus =
      filter === "All" || (filter === "Acknowledged" ? alarm.acknowledged : !alarm.acknowledged);
    const matchesSeverity = severityFilter === "All" || alarm.severity === severityFilter;
    const matchesLocation = locationFilter === "All" || alarm.location === locationFilter;

    return matchesStatus && matchesSeverity && matchesLocation;
  });

  // Get unique severity and location options for filters
  const uniqueSeverities = Array.from(new Set(alarmData.map((alarm) => alarm.severity))).concat("All");
  const uniqueLocations = Array.from(new Set(alarmData.map((alarm) => alarm.location))).concat("All");

  return (
    <div className="mt-6 bg-gray-800 rounded-lg shadow-lg p-4 overflow-x-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-white text-lg font-bold">Current Alarms</h2>
        
        {/* Filters */}
        <div className="flex space-x-2">
          <select
            className="bg-gray-700 text-white rounded p-2"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="All">All</option>
            <option value="Acknowledged">Acknowledged</option>
            <option value="Unacknowledged">Unacknowledged</option>
          </select>

          <select
            className="bg-gray-700 text-white rounded p-2"
            value={severityFilter}
            onChange={(e) => setSeverityFilter(e.target.value)}
          >
            <option value="All">All Severities</option>
            {uniqueSeverities.map((severity) => (
              <option key={severity} value={severity}>{severityLabel[severity] || severity}</option>
            ))}
          </select>

          <select
            className="bg-gray-700 text-white rounded p-2"
            value={locationFilter}
            onChange={(e) => setLocationFilter(e.target.value)}
          >
            <option value="All">All Locations</option>
            {uniqueLocations.map((location) => (
              <option key={location} value={location}>{location || "Unknown Location"}</option>
            ))}
          </select>
        </div>
      </div>

      <table className="min-w-full bg-gray-700 text-white rounded-lg">
        <thead>
          <tr className="bg-gray-600">
            <th className="py-3 px-4 text-left">System</th>
            <th className="py-3 px-4 text-left">Severity</th>
            <th className="py-3 px-4 text-left">Location</th>
            <th className="py-3 px-4 text-left">Status</th>
            <th className="py-3 px-4 text-left">Escalation</th>
            <th className="py-3 px-4 text-left">Active Time</th>
            <th className="py-3 px-4 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredAlarms.length > 0 ? (
            filteredAlarms.map((alarm) => (
              <tr key={alarm.id} className="transition duration-300 bg-gray-700">
                <td className="py-2 px-4 font-medium">{alarm.system || "Unknown System"}</td>
                <td className="py-2 px-4">
                  <FontAwesomeIcon
                    icon={
                      alarm.severity === "Red"
                        ? faExclamationCircle
                        : alarm.severity === "Orange"
                        ? faFireAlt
                        : alarm.severity === "Yellow"
                        ? faShieldAlt
                        : faCheckCircle
                    }
                    className={`mr-2 ${
                      alarm.severity === "Red"
                        ? "text-red-500"
                        : alarm.severity === "Orange"
                        ? "text-orange-500"
                        : alarm.severity === "Yellow"
                        ? "text-yellow-500"
                        : "text-green-500"
                    }`}
                  />
                  {severityLabel[alarm.severity] || "N/A"}
                </td>
                <td className="py-2 px-4">
                  {alarm.location || "Unknown Location"} - {alarm.sublocation || "Unknown Sublocation"}
                </td>
                <td className="py-2 px-4">{alarm.acknowledged ? "Acknowledged" : "Unacknowledged"}</td>
                <td className="py-2 px-4">{alarm.escalation || "Pending"}</td>
                <td className="py-2 px-4">{getActiveTime(alarm)}</td>
                <td className="py-2 px-4 flex space-x-2">
                  {!alarm.acknowledged && (
                    <button
                      onClick={() => acknowledgeAlarm(alarm.id)}
                      className="text-green-500 hover:text-green-600 p-1"
                      title="Acknowledge"
                    >
                      <FontAwesomeIcon icon={faCheckCircle} className="text-lg" />
                    </button>
                  )}
                  <button
                    onClick={() => deleteAlarm(alarm.id)}
                    className="text-red-500 hover:text-red-600 p-1"
                    title="Delete"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} className="text-lg" />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center text-white py-4">
                No alarms available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AlarmTable;
