import React, { useEffect, useState } from 'react';
import { FaCar, FaChartBar, FaCheckCircle, FaExclamationCircle, FaMotorcycle, FaThumbsUp } from 'react-icons/fa'; // Importing icons
import ViolationTimePeriodsChart from './ViolationTrendsChart';
import ViolationDonutChart from './ViolationDonutChart';

// KPIComponent as part of the parent
const KPIComponent = ({ title, value, darkMode }) => {
    const getIcon = (title) => {
      switch (title) {
        case "Number of 2 wheels":
          return <FaMotorcycle className="text-blue-500 text-xl" />;
        case "Number of 4 wheels":
          return <FaCar className="text-green-500 text-xl" />;
        case "Avg Temperature":
          return <span className="text-yellow-500 text-xl">🌡️</span>;
        case "Avg Penalty Price":
          return <span className="text-red-500 text-xl">💵</span>;
        default:
          return null;
      }
    };
  
    return (
      <div className={`flex items-center p-4 rounded-lg shadow-md ${darkMode ? 'bg-gray-800/80 text-white' : 'bg-white/20 text-white'}`}>
        <div className="flex-shrink-0 mr-3 mb-6">{getIcon(title)}</div>
        <div className="flex flex-col">
          <div className="text-sm font-semibold">{title}</div>
          <div className="text-2xl font-bold">{value}</div>
        </div>
      </div>
    );
  };
  

const VucaBottomBarV2 = ({ darkMode }) => {
  const [kpiData, setKpiData] = useState({
    totalVehicles: 0,
    avgPenalty: 0,
    avgTemperature: 0,
  });

  useEffect(() => {
    const jsonFilePath = process.env.PUBLIC_URL + '/data summary.json';

    fetch(jsonFilePath)
      .then((response) => response.json())
      .then((jsonData) => {
        // Calculate KPIs based on jsonData
        const totalVehicles = jsonData.reduce((acc, item) => acc + item.total_data_points, 0);
        const totalPenalties = jsonData.reduce((acc, item) => acc + item.total_penalty, 0);
        const totalTemperatures = jsonData.reduce((acc, item) => acc + item.average_temperature, 0);
        
        const avgPenalty = jsonData.length ? (totalPenalties / jsonData.length) : 0; // Avoid division by zero
        const avgTemperature = jsonData.length ? (totalTemperatures / jsonData.length) : 0; // Avoid division by zero
        
        setKpiData({
          totalVehicles,
          avgPenalty,
          avgTemperature,
        });
      })
      .catch((error) => console.error('Error loading the JSON file:', error));
  }, []);

  return (
<div className="flex flex-col items-center justify-end h-screen bg-transparent">
{/* Container for KPIs and charts */}
      <div className="bg-black/50 w-full max-w-5xl p-2 rounded-lg flex flex-col items-center justify-center">
        
        {/* KPI section */}
        <div className="grid grid-cols-4 gap-2 w-full mb-2">
          <KPIComponent title="Number of 2 wheels" value='13,762' darkMode={darkMode} />
          <KPIComponent title="Number of 4 wheels" value='34,545' darkMode={darkMode} />
          <KPIComponent title="Avg Temperature" value='33.24 C' darkMode={darkMode} />
          <KPIComponent title="Avg Penalty Price" value="238.08" darkMode={darkMode} /> {/* Placeholder for KPI 4 */}
        </div>

        {/* Chart section with Donut chart on the right */}
        <div className="flex w-full justify-between items-start">
          <div className="w-3/5 pr-2"> {/* Time periods chart */}
            <ViolationTimePeriodsChart darkMode={darkMode} />
          </div>
          <div className="w-2/5 pl-4"> {/* Donut chart */}
            <ViolationDonutChart darkMode={darkMode} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VucaBottomBarV2;
