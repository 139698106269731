import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const SystemAlertsChart = ({ data }) => {
  if (!data || !data.systemAlerts) {
    return <div className="text-white text-center">Loading system alerts...</div>;
  }

  const systems = ['CCTV', 'ACS', 'IDS', 'Fire', 'Agents'];

  const onlineData = systems.map(system => data.systemAlerts[system]?.online || data.systemAlerts[system]?.onDuty || 0);
  const offlineData = systems.map(system => data.systemAlerts[system]?.offline || data.systemAlerts[system]?.offDuty || 0);

  const chartData = {
    series: [
      {
        name: 'Online/On Duty',
        data: onlineData,
      },
      {
        name: 'Offline/Off Duty',
        data: offlineData,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false,
        },
        background: '#1F2937',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      xaxis: {
        categories: systems,
        labels: {
          style: {
            colors: '#E5E7EB',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#E5E7EB',
          },
        },
      },
      fill: {
        opacity: 1,
        colors: ['#34D399', '#EF4444'],
      },
      grid: {
        borderColor: '#374151',
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center',
        labels: {
          colors: '#E5E7EB',
        },
      },
      tooltip: {
        theme: 'dark',
      },
    },
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex items-center mb-2">
        <FontAwesomeIcon icon={faExclamationTriangle} className="text-yellow-500 mr-2" />
        <h2 className="text-white text-lg">System Alerts - Online vs Offline</h2>
      </div>
      <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height="90%" />
    </div>
  );
};

export default SystemAlertsChart;
