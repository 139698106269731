import React, { useEffect, useState } from "react";
import LeafletMap from "./components/Leafletmap";
import OKPI from "./components/Kpi";
import {
  faBars,
  faBell,
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faCogs,
  faShieldAlt,
  faUserSecret,
  faMaskVentilator,
  faServer,
  faSquareParking,
  faBellConcierge,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../img/color1_text-logoname_transparent_background.png";
import LocationDonutChart from "./charts/Alarms/LocationDonut";
import AlarmTable from "./components/AlertsTable";
import DeviceFailuresChart from "./charts/DeviceFailuresChart";
import SystemAlertsChart from "./charts/SystemAlertsChart";
import SystemFailuresChart from "./charts/SystemFailurechart";
import BMSAlarmTable from "./charts/bms/BmsAlarmsTable";
import LightingAlertsChart from "./charts/bms/LightingAlertsChart ";
import WaterLeakAlertsCard from "./charts/bms/WaterLeakAlertsChart";
import NetworkUptimeDowntimeCard from "./charts/iot/NetworkUptimeDowntimeCard";
import ServerOverloadFailureChart from "./charts/iot/ServerOverloadFailureChart";
import StorageBackupAlertsCard from "./charts/iot/StorageBackupAlertsCard";
import CybersecurityPowerAlerts from "./charts/iot/CybersecurityIncidents";
import PowerSupplyFailures from "./charts/iot/PowerSupplyFailures";
import BackupDataRecoveryFailures from "./charts/iot/Backupalerts";
import IoTDataSummaryTable from "./charts/iot/IoTDataSummaryTable ";
import ParkingSpaceAlerts from "./charts/parking/ParkingSpaceAlerts";
import AccessControlAlarms from "./charts/parking/AccessControlAlarms";
import ParkingOverstayAlerts from "./charts/parking/ParkingOverstayAlerts";
import ParkingSummaryTable from "./charts/parking/ParkingSummaryTable";
import SystemAlerts from "./charts/Alarms/SystemAlerts";
import HVACFailuresChart from "./charts/bms/Energylinechart";
import StatusTable from "./charts/SecuritySystems/Statustable.js";

const OodaAlarm = () => {
  const [alarmData, setAlarmData] = useState([]);
  const [ssData, setssData] = useState(null);
  const [bmsData, setBmsData] = useState(null); // Initialize as null



  const [filters, setFilters] = useState({
    systemType: "All",
    severity: "All",
    acknowledged: "All",
    timeFrame: "All",
    location: "All",
  });

  useEffect(() => {
    fetch("/oodabmsdata.json")
      .then((response) => response.json())
      .then((data) => {
        setBmsData(data); // Directly set the array
        console.log("bms", data);
      })
      .catch((error) => console.error("Error fetching the alarm data:", error));
  }, []);

  useEffect(() => {
    fetch("/oodasystemssecurity.json")
      .then((response) => response.json())
      .then((data) => {
        setssData(data);
        console.log("datass", data);
      })
      .catch((error) => console.error("Error fetching the alarm data:", error));
  }, []);

  useEffect(() => {
    fetch("/oodaalarmsdata.json")
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched alarms:", data); // Check if the data is correct
        setAlarmData(data);
      })
      .catch((error) => console.error("Error fetching the alarm data:", error));
  }, []);

  const criticalAlarms = alarmData.filter((alarm) => alarm.severity === "Red")
    .length;
  const majorAlarms = alarmData.filter((alarm) => alarm.severity === "Orange")
    .length;
  const minorAlarms = alarmData.filter((alarm) => alarm.severity === "Yellow")
    .length;
  const clearedAlarms = alarmData.filter((alarm) => alarm.severity === "Green")
    .length;

  // KPI calculations
  const systemFailures = ssData ? ssData.systemFailures.length : 0;
  const deviceFailures = ssData ? ssData.deviceFailures.length : 0;
  const unauthorizedEntries = ssData
    ? ssData.unauthorizedEntryAttempts.length
    : 0;
  const panicButtonActivations = ssData
    ? ssData.panicButtonActivations.length
    : 0;

  return (
    <div className="flex h-screen  bg-gray-900 ml-20">
        <header className="fixed top-0 left-0 right-0 z-50 flex justify-between p-4 shadow-md bg-gray-900 mb-4">
      <div className="flex items-center ml-28">
        <FontAwesomeIcon icon={faBell} className="text-xl text-white mr-6" />
        <span className="text-white text-2xl font-semibold">Alarms</span>
      </div>
      <div className="flex items-center">
        <img src={logo} alt="Logo" className="h-16 w-auto" />
      </div>
    </header>

      <div className="flex-1 flex flex-col overflow-y-auto bg-gray-900 mt-20 p-4">
        <div className="flex items-center ml-15 text-left">
          <FontAwesomeIcon icon={faBell} className="text-blue-500 mr-2" />{" "}
          {/* Blue icon */}
          <p className="text-white mt-2 font-serif text-lg mt-3">
            General Alarms
          </p>{" "}
          {/* Change font and size */}
        </div>

        {/* KPIs Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
          <OKPI
            title="Critical Alarms"
            value={criticalAlarms}
            icon={faExclamationTriangle}
            bgColor="bg-gradient-to-r from-red-500 to-red-700"
          />
          <OKPI
            title="Major Alarms"
            value={majorAlarms}
            icon={faExclamationCircle}
            bgColor="bg-gradient-to-r from-orange-500 to-orange-700"
          />
          <OKPI
            title="Minor Alarms"
            value={minorAlarms}
            icon={faBell}
            bgColor="bg-gradient-to-r from-yellow-500 to-yellow-700"
          />
          <OKPI
            title="Cleared Alarms"
            value={clearedAlarms}
            icon={faCheckCircle}
            bgColor="bg-gradient-to-r from-green-500 to-green-700"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6 mt-8">
  {/* Map Component */}
  <div className="col-span-1 md:col-span-2 bg-gray-800 p-4 rounded-md shadow-md transition-shadow hover:shadow-xl">
    <div className="bg-gray-700 h-64 max-h-64 rounded-lg overflow-hidden">
      <LeafletMap data={alarmData} />
    </div>
  </div>

  {/* Donut Chart Component (Alarms by Location) */}
  <div className="col-span-1 bg-gray-800 p-4 rounded-md shadow-md transition-shadow hover:shadow-xl">
    <div className="bg-gray-800 h-64 max-h-64 rounded-lg flex items-center justify-center ">
      <LocationDonutChart
        data={alarmData}
        darkMode={true}
        title="Alarms by Location"
      />
    </div>
  </div>

  {/* System Alerts Component */}
  <div className="bg-gray-900 h-64 max-h-64 rounded-lg flex items-center justify-center">
    <SystemAlerts />
  </div>
</div>


        {/* Alarm Table Section at the bottom */}
        <div className="col-span-4">
          <AlarmTable
            alarms={alarmData}
            acknowledgeAlarm={(id) => console.log(`Acknowledged alarm: ${id}`)}
            deleteAlarm={(id) => console.log(`Deleted alarm: ${id}`)}
            filters={filters}
            setFilters={setFilters}
          />
        </div>

        <div className="flex items-center ml-15 text-left mt-2">
          <FontAwesomeIcon icon={faShieldAlt} className="text-blue-500 mr-2" />{" "}
          {/* Shield icon for security */}
          <p className="text-white mt-2 font-serif text-lg mt-3">
            Security Systems
          </p>{" "}
          {/* Change font and size */}
        </div>

        {/* Security Systems Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
          <OKPI
            title="System Failures"
            value={systemFailures}
            icon={faCogs}
            bgColor="bg-gradient-to-r from-red-500 to-red-700"
          />
          <OKPI
            title="Device Failures"
            value={deviceFailures}
            icon={faShieldAlt}
            bgColor="bg-gradient-to-r from-orange-500 to-orange-700"
          />
          <OKPI
            title="Unauthorized Entries"
            value={unauthorizedEntries}
            icon={faUserSecret}
            bgColor="bg-gradient-to-r from-blue-500 to-blue-700"
          />
          <OKPI
            title="Panic Button Activations"
            value={panicButtonActivations}
            icon={faExclamationCircle}
            bgColor="bg-gradient-to-r from-red-500 to-red-700"
          />
        </div>
       

        {/* Updated height for the 3 charts */}
        <div className="w-full px-0 mt-6">
          <div className="grid grid-cols-3 gap-4">
            {/* Row 1: Three charts with increased height */}
            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              {" "}
              {/* Increased height */}
              <SystemFailuresChart data={ssData} />
            </div>
            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              {" "}
              {/* Increased height */}
              <DeviceFailuresChart />
            </div>
            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              {" "}
              {/* Increased height */}
              <SystemAlertsChart data={ssData} />
            </div>
          </div>
          <div className="mt-6">
        <StatusTable data={ssData} />
        </div>
        </div>
        <div className="flex items-center ml-15 text-left mt-2">
          <FontAwesomeIcon
            icon={faMaskVentilator}
            className="text-blue-500 mr-2"
          />{" "}
          {/* Shield icon for security */}
          <p className="text-white mt-2 font-serif text-lg mt-3">Bmc</p>{" "}
          {/* Change font and size */}
        </div>

        {/* Updated height for the 3 charts */}
        <div className="w-full px-0 mt-6">
          <div className="grid grid-cols-3 gap-4">
            {/* Row 1: Three charts with increased height */}
            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              {" "}
              {/* Increased height */}
              <HVACFailuresChart />
            </div>
            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              {" "}
              {/* Increased height */}
              <WaterLeakAlertsCard />
            </div>

            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              {" "}
              {/* Increased height */}
              <LightingAlertsChart />
            </div>
          </div>
          <div className="mt-6">
            <BMSAlarmTable alarms={bmsData} />;
          </div>
        </div>
        <div className="flex items-center ml-15 text-left mt-2">
          <FontAwesomeIcon icon={faServer} className="text-blue-500 mr-2" />{" "}
          {/* Shield icon for security */}
          <p className="text-white mt-2 font-serif text-lg mt-3">
            IT Infrastructure & Hardware
          </p>{" "}
          {/* Change font and size */}
        </div>

        <div className="w-full px-0 mt-6">
          <div className="grid grid-cols-3 gap-4">
            {/* Row 1: Three charts with increased height */}
            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              {" "}
              {/* Increased height */}
              <ServerOverloadFailureChart />
            </div>
            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              {" "}
              {/* Increased height */}
              <NetworkUptimeDowntimeCard />
            </div>

            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              {" "}
              {/* Increased height */}
              <StorageBackupAlertsCard />
            </div>
          </div>
        </div>
        <div className="w-full px-0 mt-6">
          <div className="grid grid-cols-3 gap-4">
            {/* Row 1: Three charts with increased height */}
            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              {" "}
              {/* Increased height */}
              <CybersecurityPowerAlerts />
            </div>
            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              {" "}
              {/* Increased height */}
              <PowerSupplyFailures />
            </div>

            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              {" "}
              {/* Increased height */}
              <BackupDataRecoveryFailures />
            </div>
          </div>

          <div className="mt-6">
            <IoTDataSummaryTable />;
          </div>
        </div>

        <div className="flex items-center ml-15 text-left mt-2">
          <FontAwesomeIcon
            icon={faSquareParking}
            className="text-blue-500 mr-2"
          />{" "}
          {/* Shield icon for security */}
          <p className="text-white mt-2 font-serif text-lg mt-3">
            Parking
          </p>{" "}
          {/* Change font and size */}
        </div>

        <div className="w-full px-0 mt-6">
          <div className="grid grid-cols-3 gap-4">
            {/* Row 1: Three charts with increased height */}
            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              {" "}
              {/* Increased height */}
              <ParkingSpaceAlerts />
            </div>
            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              {" "}
              {/* Increased height */}
              <AccessControlAlarms />
            </div>

            <div className="bg-gray-800 p-4 rounded-lg w-full h-80">
              {" "}
              {/* Increased height */}
              <ParkingOverstayAlerts />
            </div>
          </div>

          <div className="mt-6">
            <ParkingSummaryTable />;
          </div>
        </div>
      </div>
    </div>
  );
};

export default OodaAlarm;
