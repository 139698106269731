import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faCheckCircle, faClock, faExclamationTriangle, faTrophy } from '@fortawesome/free-solid-svg-icons';
import Chart from 'react-apexcharts';

const OverallAgentStatsCard = ({ agents = [] }) => {
    // Calculate statistics
    const totalAgents = agents.length;
    const availableAgents = agents.filter(agent => agent.availability === 'available').length;
    const busyAgents = agents.filter(agent => agent.availability === 'busy').length;
    const offlineAgents = agents.filter(agent => agent.availability === 'offline').length;
    const onMissionAgents = agents.filter(agent => agent.onMission).length;

    // Average statistics
    const avgResolvedIssues = (agents.reduce((sum, agent) => sum + agent.resolvedIssues, 0) / totalAgents).toFixed(1);
    const avgResponseTime = (agents.reduce((sum, agent) => sum + agent.responseTime, 0) / totalAgents).toFixed(1);
    const avgPerformanceRating = (agents.reduce((sum, agent) => sum + agent.performanceRating, 0) / totalAgents).toFixed(1);

    // Chart options
    const chartOptions = {
        chart: {
            type: 'radialBar',
            offsetY: -5,
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    size: '60%',
                },
                dataLabels: {
                    name: {
                        fontSize: '10px',
                        color: '#fff',
                        offsetY: 3,
                    },
                    value: {
                        fontSize: '12px',
                        color: '#fff',
                        formatter: (val) => `${val}%`,
                    },
                },
            },
        },
        colors: ['#00E396', '#FF4560'],
        labels: ['Available %', 'On Mission %'],
    };

    const chartSeriesAvailable = [(availableAgents / totalAgents * 100).toFixed(1)];
    const chartSeriesMission = [(onMissionAgents / totalAgents * 100).toFixed(1)];

    return (
        <div className="bg-gray-800 rounded-lg text-white text-xs">
           <h3 className="text-lg font-semibold mb-4 flex items-center text-white">
        <FontAwesomeIcon icon={faClock} className="mr-2 text-blue-400" />
       Agents statistics
      </h3>
          
            <div className="flex justify-between mb-3">
                <div className="bg-green-700 p-2 rounded text-center flex-1 mx-1">
                    <h3 className="font-semibold text-xs">Avg. Resolved Issues</h3>
                    <p className="text-sm">{avgResolvedIssues}</p>
                </div>
                <div className="bg-purple-700 p-2 rounded text-center flex-1 mx-1">
                    <h3 className="font-semibold text-xs">Avg. Response Time</h3>
                    <p className="text-sm">{avgResponseTime} min</p>
                </div>
                <div className="bg-orange-700 p-2 rounded text-center flex-1 mx-1">
                    <h3 className="font-semibold text-xs">Avg. Rating</h3>
                    <p className="text-sm">{avgPerformanceRating}</p>
                    <FontAwesomeIcon icon={faTrophy} className="text-sm mt-1" />
                </div>
            </div>
            <div className="flex justify-between">
                <div className="flex-1 text-center">
                    <h3 className="text-xs font-semibold">Availability Gauge</h3>
                    <Chart options={chartOptions} series={chartSeriesAvailable} type="radialBar" height={110} />
                </div>
                <div className="flex-1 text-center">
                    <h3 className="text-xs font-semibold">Mission Gauge</h3>
                    <Chart options={chartOptions} series={chartSeriesMission} type="radialBar" height={110} />
                </div>
            </div>
        </div>
    );
};

export default OverallAgentStatsCard;
