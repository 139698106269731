import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { FaLock } from 'react-icons/fa';

const AccessControlAlarms = () => {
  // Fake data for Access Control Alarms
  const entryData = [
    { month: "Jan", carsEntered: 18, gateOpen: 20 },
    { month: "Feb", carsEntered: 22, gateOpen: 25 },
    { month: "Mar", carsEntered: 15, gateOpen: 17 },
    { month: "Apr", carsEntered: 28, gateOpen: 30 },
    { month: "May", carsEntered: 35, gateOpen: 37 },
    { month: "Jun", carsEntered: 30, gateOpen: 33 },
    { month: "Jul", carsEntered: 40, gateOpen: 42 },
    { month: "Aug", carsEntered: 34, gateOpen: 36 },
    { month: "Sep", carsEntered: 30, gateOpen: 31 },
    { month: "Oct", carsEntered: 25, gateOpen: 27 },
    { month: "Nov", carsEntered: 20, gateOpen: 22 },
    { month: "Dec", carsEntered: 18, gateOpen: 19 },
  ];

  // Calculate number of cars currently inside the parking
  const totalCars = 100; // Example total capacity
  const totalEntered = entryData.reduce((acc, item) => acc + item.carsEntered, 0);
  const carsInside = Math.min(totalCars, totalEntered);

  // Prepare data for the Access Control chart
  const chartData = {
    series: [
      {
        name: 'Cars Entered',
        data: entryData.map(item => item.carsEntered),
      },
      {
        name: 'Gate Open',
        data: entryData.map(item => item.gateOpen),
      },
    ],
    categories: entryData.map(item => item.month),
  };

  // Chart options for Access Control Alarms
  const chartOptions = {
    chart: {
      type: 'bar',
      height: '100%',
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '70%',
      },
    },
    colors: ['#FF4560', '#00E396'],
    xaxis: {
      categories: chartData.categories,
      title: {
        text: 'Month',
        style: {
          color: '#FFFFFF',
        },
      },
      labels: {
        style: {
          colors: '#FFFFFF',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Number of Vehicles',
        style: {
          color: '#FFFFFF',
        },
      },
      min: 0,
      max: Math.max(...entryData.map(item => item.carsEntered), ...entryData.map(item => item.gateOpen)) + 10,
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      floating: true,
      offsetY: 0,
      labels: {
        colors: '#FFFFFF',
      },
    },
    grid: {
      padding: {
        top: 10,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#FFFFFF'],
      },
    },
  };

  return (
    <div className="bg-gray-800 rounded-xl p-4 shadow-lg flex flex-col h-64">
      <h2 className="text-xl font-extrabold mb-2 flex items-center text-white">
        <FaLock className="mr-2 text-blue-300" /> Access Control Alarms
      </h2>
      <div className="flex justify-between text-white mb-4">
        <p>Cars Inside: <span className="font-bold text-green-400">{carsInside}</span></p>
      </div>
      <div className="h-48"> {/* Adjusted height for consistency with other components */}
        <ReactApexChart options={chartOptions} series={chartData.series} type="bar" height="100%" />
      </div>
    </div>
  );
};

export default AccessControlAlarms;
