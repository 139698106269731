import { useState } from "react";
import Ooda_Side_Bar from "../components/Ooda_side_Bar";

import { Outlet } from "react-router";
function OodaSidebarLayout() {
    return (
        <>
            <Ooda_Side_Bar />
            <Outlet />
        </>

    );
}


export default OodaSidebarLayout