import React, { useEffect, useState } from 'react';
import KPI from './KPI'; // Import the KPI component

const VucaAlbateenright = () => {
  const [data, setData] = useState({
    temperature: '0°C',
    totalVehicles: '0',
    trafficDensity: '0%',
    totalViolations: '0',
    estimatedFine: '0',
  });

  useEffect(() => {
    document.body.style.backgroundColor = 'transparent';
    document.getElementById('root').style.backgroundColor = 'transparent';
    const almuteenaData = {
        totalVehicles: '63',  // Total vehicles in the area
        totalViolations: '63', // Count of violations where "is_viol" is "1"
        trafficDensity: '100%', // Based on the violation vs non-violation data
        estimatedFine: '48,650', // Total fines for violations
        temperature: '33.2°C', // Average temperature
      };

    setData({
      temperature: almuteenaData.temperature,
      totalVehicles: almuteenaData.totalVehicles,
      trafficDensity: almuteenaData.trafficDensity,
      dailyViolations: almuteenaData.dailyViolations,
      estimatedFine: almuteenaData.estimatedFine,
    });
  }, []);

  return (
    <div className="h-screen flex flex-col items-end pr-12 bg-transparent mt-20">
      <h2 style={{ color: 'white', marginBottom: '20px' }}>Albateen</h2>
      <KPI icon="fas fa-thermometer-half" value={data.temperature} label="Temperature" valueColor="#FF4500" />
      <KPI icon="fas fa-car" value={data.totalVehicles} label="Total Vehicles" valueColor="#ccc" />
      <KPI icon="fas fa-road" value={data.trafficDensity} label="Traffic Density" valueColor="#28b16f" />
      <KPI icon="fas fa-exclamation-triangle" value={data.totalViolationsViolations} label="Total Violations" valueColor="#FFA500" />
      <KPI icon="fas fa-money-bill" value={data.estimatedFine} label="Estimated Fine" valueColor="#ccc" />
    </div>
  );
};

export default VucaAlbateenright;
