import React, { useEffect, useState } from 'react';
import V2KPI from '../V2kpi';

const VucaParisRightBarV2 = () => {
  const [data, setData] = useState({
    temperature: '0°C',
    totalVehicles: '0',
    trafficDensity: '0%',
    totalViolations: '0',
    estimatedFine: '0',
  });

  useEffect(() => {
    document.body.style.backgroundColor = 'transparent';
    document.getElementById('root').style.backgroundColor = 'transparent';

    // Fake data for demonstration purposes
    const fakeData = [
      { total_data_points: 487321, total_violations: 10345, total_penalty: 4987654, average_temperature: 16.8 },
      { total_data_points: 459823, total_violations: 12789, total_penalty: 5532110, average_temperature: 17.2 },
      { total_data_points: 521456, total_violations: 11560, total_penalty: 5103450, average_temperature: 15.5 },
    ];

    const totalVehicles = fakeData.reduce((sum, item) => sum + item.total_data_points, 0);
    const totalViolations = fakeData.reduce((sum, item) => sum + item.total_violations, 0);
    const totalPenalty = fakeData.reduce((sum, item) => sum + item.total_penalty, 0);
    const avgTemperature = (fakeData.reduce((sum, item) => sum + item.average_temperature, 0) / fakeData.length).toFixed(1);

    setData({
      temperature: `${avgTemperature}°C`,
      totalVehicles: totalVehicles.toLocaleString(),
      trafficDensity: `${((totalViolations / totalVehicles) * 100).toFixed(2)}%`,
      totalViolations: totalViolations.toLocaleString(),
      estimatedFine: (totalPenalty / totalViolations).toLocaleString(), // Average fine per violation
    });

    return () => {
      document.body.style.backgroundColor = null;
      document.getElementById('root').style.backgroundColor = null;
    };
  }, []);

  return (
    <div className="flex flex-col justify-start items-end pr-12 bg-transparent" style={{ marginTop: '90px', height: '100%', overflowY: 'hidden' }}>
      <V2KPI icon="fas fa-thermometer-half" value={data.temperature} label="Temperature" valueColor="#FF4500" />
      <V2KPI icon="fas fa-car" value="26,543"label="Total Unique Vehicles" valueColor="#ccc" />
      <V2KPI icon="fas fa-road" value={data.trafficDensity} label="Traffic Density" valueColor="#28b16f" />
      <V2KPI icon="fas fa-exclamation-triangle" value={data.totalViolations} label="Total Violations" valueColor="#FFA500" />
      <V2KPI icon="fas fa-money-bill" value={data.estimatedFine} label="AVG Estimated Fine" valueColor="#ccc" />
    </div>
  );
};

export default VucaParisRightBarV2;
