import React, { useEffect, useState } from 'react';
import KPI from './KPI'; // Import the KPI component

const VucaAlmuteenaright = () => {
  const [data, setData] = useState({
    temperature: '0°C',
    totalVehicles: '0',
    trafficDensity: '0%',
    totalViolations: '0',
    estimatedFine: '0',
  });

  useEffect(() => {
    document.body.style.backgroundColor = 'transparent';
    document.getElementById('root').style.backgroundColor = 'transparent';
    // Almuteena-specific data hardcoded or fetched from API
    const almuteenaData = {
      totalVehicles: '63',
      dailyViolations: '63',
      trafficDensity: '100%',
      estimatedFine: '48,650',
      temperature: '33.2°C',
    };

    // Set the Almuteena-specific data
    setData({
      temperature: almuteenaData.temperature,
      totalVehicles: almuteenaData.totalVehicles,
      trafficDensity: almuteenaData.trafficDensity,
      dailyViolations: almuteenaData.dailyViolations,
      estimatedFine: almuteenaData.estimatedFine,
    });
  }, []);

  return (
    <div className="h-screen flex flex-col justify-center items-end pr-12 bg-transparent">
      <h2 style={{ color: 'white', marginBottom: '20px' }}>Almuteena</h2>
      <KPI icon="fas fa-thermometer-half" value={data.temperature} label="Temperature" valueColor="#FF4500" />
      <KPI icon="fas fa-car" value={data.totalVehicles} label="Total Vehicles" valueColor="#ccc" />
      <KPI icon="fas fa-road" value={data.trafficDensity} label="Traffic Density" valueColor="#28b16f" />
      <KPI icon="fas fa-exclamation-triangle" value={data.dailyViolations} label="Total Violations" valueColor="#FFA500" />
      <KPI icon="fas fa-money-bill" value={data.estimatedFine} label="Estimated Fine" valueColor="#ccc" />
    </div>
  );
};

export default VucaAlmuteenaright;
