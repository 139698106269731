import React, { useEffect, useState } from 'react';
import KPI from './KPI'; 

const Vucaright = () => {
  const [data, setData] = useState({
    temperature: '0°C',
    totalVehicles: '0',
    trafficDensity: '0%',
    totalViolationss: '0',
    estimatedFine: '0'
  });

  useEffect(() => {
    // Set body background and root div transparent to ensure transparency
    document.body.style.backgroundColor = 'transparent';
    document.getElementById('root').style.backgroundColor = 'transparent';

    const csvFilePath = process.env.PUBLIC_URL + '/cleaned_smartcity_dataset.csv';

    fetch(csvFilePath)
      .then((response) => response.text())
      .then((csvText) => {
        const rows = csvText.split('\n').filter(Boolean);
        const headers = rows[0].split(',');
        const parsedData = rows.slice(1).map(row => {
          const values = row.split(',');
          const entry = {};
          headers.forEach((header, index) => {
            entry[header] = values[index];
          });
          return entry;
        });

        const totalVehicles = parsedData.length;
        const totalViolations = parsedData.filter(vehicle => vehicle.is_viol === '1').length;
        const totalPenalty = parsedData.reduce((sum, vehicle) => sum + parseFloat(vehicle.traffic_penalty || 0), 0);
        const avgTemperature = (parsedData.reduce((sum, vehicle) => sum + parseFloat(vehicle.temperature || 0), 0) / totalVehicles).toFixed(1);

        setData({
          temperature: `${avgTemperature}°C`,
          totalVehicles: totalVehicles.toLocaleString(),
          trafficDensity: `${((totalViolations / totalVehicles) * 100).toFixed(2)}%`,
          totalViolationss: totalViolations.toLocaleString(),
          estimatedFine: totalPenalty.toLocaleString()
        });
      })
      .catch((error) => console.error('Error loading the CSV file:', error));

    return () => {
      // Cleanup background style on component unmount
      document.body.style.backgroundColor = null;
      document.getElementById('root').style.backgroundColor = null;
    };
  }, []);

  return (
    <div className="h-screen flex flex-col justify-center items-end pr-12 bg-transparent">
      <KPI icon="fas fa-thermometer-half" value={data.temperature} label="Temperature" valueColor="#FF4500" />
      <KPI icon="fas fa-car" value={data.totalVehicles} label="Total Vehicles" valueColor="#ccc" />
      <KPI icon="fas fa-road" value={data.trafficDensity} label="Traffic Density" valueColor="#28b16f" />
      <KPI icon="fas fa-exclamation-triangle" value={data.totalViolationss} label="Total Violations" valueColor="#FFA500" />
      <KPI icon="fas fa-money-bill" value={data.estimatedFine} label="Estimated Fine" valueColor="#ccc" />
    </div>
  );
};

export default Vucaright;
