import React from 'react';

const severityStyles = {
  Critical: 'bg-red-500 text-white',
  Major: 'bg-orange-500 text-white',
  Minor: 'bg-yellow-400 text-black',
  Warning: 'bg-yellow-300 text-black',
};

const BMSAlarmTable = ({ alarms = [] }) => {
  if (!Array.isArray(alarms)) {
    return <div>No alarms available.</div>;
  }

  return (
    <div className="overflow-x-auto rounded-lg ">
      <table className="min-w-full bg-gray-800 text-white rounded-lg">
        <thead className="bg-gray-700">
          <tr>
            <th className="py-3 px-4 text-left">Type</th>
            <th className="py-3 px-4 text-left">Timestamp</th>
            <th className="py-3 px-4 text-left">Severity</th>
            <th className="py-3 px-4 text-left">Location</th>
          </tr>
        </thead>
        <tbody>
          {alarms.length === 0 ? (
            <tr>
              <td colSpan="4" className="py-2 text-center text-gray-400">No alarms to display.</td>
            </tr>
          ) : (
            alarms.map((alarm, index) => (
              <tr key={index} className="border-b border-gray-700 hover:bg-gray-600 transition duration-200">
                <td className="py-2 px-4">{alarm.type}</td>
                <td className="py-2 px-4">{new Date(alarm.timestamp).toLocaleString()}</td>
                <td className={`py-2 px-4 ${severityStyles[alarm.severity]}`}>{alarm.severity}</td>
                <td className="py-2 px-4">{alarm.location}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BMSAlarmTable;
