import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const ViolationTimePeriodsChartDubai2 = ({ darkMode }) => {
  const [violationData, setViolationData] = useState([]);

  useEffect(() => {
    const jsonFilePath = process.env.PUBLIC_URL + '/data summary.json';

    fetch(jsonFilePath)
      .then((response) => response.json())
      .then((jsonData) => {
        setViolationData(jsonData);
      })
      .catch((error) => console.error('Error loading the JSON file:', error));
  }, []);

  const aggregateData = (data) => {
    const result = {
      Dubai2: { total: 0, Afternoon: 0, Early: 0, Evening: 0, Late: 0, Morning: 0 },
    };

    data.forEach(item => {
      if (item.month === 9 || item.month === 10) {
        if (item.area === "Dubai2") {
          result.Dubai2.total += item.total_violations;
          result.Dubai2.Afternoon += item.Afternoon;
          result.Dubai2.Early += item['Early Morning'];
          result.Dubai2.Evening += item['Evening/Night'];
          result.Dubai2.Late += item['Late Morning'];
          result.Dubai2.Morning += item.Morning;
        }
      }
    });

    return result;
  };

  const aggregatedData = aggregateData(violationData);
  const categories = ['Afternoon', 'Early', 'Evening', 'Late', 'Morning'];
  const areaDubai2Data = categories.map(category => aggregatedData.Dubai2[category]);

  const series = [
    {
      name: 'Dubai2',
      data: areaDubai2Data,
    },
  ];

  const icons = {
    Afternoon: '🌞',
    Early: '🌅',
    Evening: '🌜',
    Late: '🌇',
    Morning: '🌄',
  };

  const options = {
    chart: {
      type: 'bar',
      height: 300,
      width: '100%',
      stacked: true,
      background: 'transparent',
      toolbar: { show: false },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
      },
    },
    colors: ['#48BB78'], // Single color for Dubai2
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        borderRadius: 8,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val}`,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: darkMode ? '#fff' : '#000',
        textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
      },
    },
    xaxis: {
      categories: categories.map(category => `${icons[category]} ${category}`),
      labels: {
        style: {
          colors: darkMode ? '#fff' : '#000',
          fontSize: '12px',
          fontWeight: 'medium',
          fontFamily: 'Poppins, sans-serif',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Violations',
        style: {
          color: darkMode ? '#fff' : '#000',
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: 'Poppins, sans-serif',
        },
      },
      labels: {
        formatter: (value) => value.toLocaleString(),
        style: {
          color: darkMode ? '#fff' : '#000',
        },
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} violations`,
      },
      theme: darkMode ? 'dark' : 'light',
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      labels: {
        colors: darkMode ? '#fff' : '#000',
      },
    },
    grid: {
      borderColor: darkMode ? '#444' : '#ddd',
      strokeDashArray: 3,
      padding: {
        left: 10,
        right: 10,
      },
    },
  };

  return (
    <div className={`p-3 rounded-lg ${darkMode ? 'bg-gray-800/80 text-gray-200' : 'bg-white/30 text-gray-900'} relative`} style={{ width: '615px' }}>
      <div className="text-sm font-semibold mb-1 text-left" style={{ textShadow: '1px 1px 2px rgba(0,0,0,0.5)', marginBottom: '5px' }}>
        Violation Trends for Dubai2 by Time Period
      </div>
      <div className="relative w-full" style={{ height: '200px' }}>
        <Chart options={options} series={series} type="bar" height="200" />
      </div>
    </div>
  );
};

export default ViolationTimePeriodsChartDubai2;
