import React, { useEffect, useState } from 'react';
import { FaCar, FaMotorcycle } from 'react-icons/fa';
import ViolationTimePeriodsChartAlmuteena from './ViolationTimePeriodsChartAlmuteena';
import ViolationDonutChartAlmuteena from './ViolationDonutChartAlmuteena';

const KPIComponent = ({ title, value, darkMode }) => {
  const getIcon = (title) => {
    switch (title) {
      case "Number of 2 wheels":
        return <FaMotorcycle className="text-blue-500 text-xl" />;
      case "Number of 4 wheels":
        return <FaCar className="text-green-500 text-xl" />;
      case "Avg Temperature":
        return <span className="text-yellow-500 text-xl">🌡️</span>;
      case "Avg Penalty Price":
        return <span className="text-red-500 text-xl">💵</span>;
      default:
        return null;
    }
  };

  return (
    <div className={`flex items-center p-4 rounded-lg shadow-md ${darkMode ? 'bg-gray-800/80 text-white' : 'bg-white/20 text-black'}`}>
      <div className="flex-shrink-0 mr-3 mb-6">{getIcon(title)}</div>
      <div className="flex flex-col">
        <div className="text-sm font-semibold">{title}</div>
        <div className="text-2xl font-bold">{value}</div>
      </div>
    </div>
  );
};

const VucaAlmuteenaBottomBarV2 = ({ darkMode }) => {
  const [kpiData, setKpiData] = useState({
    totalVehicles: 0,
    avgPenalty: 0,
    avgTemperature: 0,
  });

  useEffect(() => {
    const jsonFilePath = process.env.PUBLIC_URL + '/data summary.json';

    fetch(jsonFilePath)
      .then((response) => response.json())
      .then((jsonData) => {
        const totalVehicles = jsonData.reduce((acc, item) => acc + item.total_data_points, 0);
        const totalPenalties = jsonData.reduce((acc, item) => acc + item.total_penalty, 0);
        const totalTemperatures = jsonData.reduce((acc, item) => acc + item.average_temperature, 0);

        const avgPenalty = jsonData.length ? (totalPenalties / jsonData.length) : 0; // Avoid division by zero
        const avgTemperature = jsonData.length ? (totalTemperatures / jsonData.length) : 0; // Avoid division by zero

        setKpiData({
          totalVehicles,
          avgPenalty,
          avgTemperature,
        });
      })
      .catch((error) => console.error('Error loading the JSON file:', error));
  }, []);

  return (
    <div className="flex flex-col items-center justify-end h-screen bg-transparent">
      <div className="bg-black/50 w-full max-w-5xl p-2 rounded-lg flex flex-col items-center justify-center">
        <div className="grid grid-cols-4 gap-2 w-full mb-2">
          <KPIComponent title="Number of 2 wheels" value={kpiData.totalVehicles.toLocaleString()} darkMode={darkMode} />
          <KPIComponent title="Number of 4 wheels" value={kpiData.totalVehicles.toLocaleString()} darkMode={darkMode} />
          <KPIComponent title="Avg Temperature" value={`${kpiData.avgTemperature.toFixed(2)} °C`} darkMode={darkMode} />
          <KPIComponent title="Avg Penalty Price" value={kpiData.avgPenalty.toFixed(2)} darkMode={darkMode} />
        </div>

        <div className="flex w-full justify-between items-start">
          <div className="w-3/5 pr-2">
            <ViolationTimePeriodsChartAlmuteena darkMode={darkMode} />
          </div>
          <div className="w-2/5 pl-4">
            <ViolationDonutChartAlmuteena darkMode={darkMode} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VucaAlmuteenaBottomBarV2;
