import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { FaPlug } from 'react-icons/fa';

const PowerSupplyFailures = () => {
  // Fake data for Power Supply Failures
  const powerSupplyData = [
    { month: "Jan", failures: 1 },
    { month: "Feb", failures: 0 },
    { month: "Mar", failures: 3 },
    { month: "Apr", failures: 2 },
    { month: "May", failures: 4 },
    { month: "Jun", failures: 1 },
    { month: "Jul", failures: 0 },
    { month: "Aug", failures: 5 },
    { month: "Sep", failures: 3 },
    { month: "Oct", failures: 1 },
    { month: "Nov", failures: 2 },
    { month: "Dec", failures: 4 },
  ];

  // Prepare data for Power Supply Failures chart
  const powerSupplyChartData = {
    series: [
      {
        name: 'Failures',
        data: powerSupplyData.map(item => item.failures),
      },
    ],
    categories: powerSupplyData.map(item => item.month),
  };

  // Chart options for Power Supply
  const powerSupplyOptions = {
    chart: {
      type: 'line',
      height: '100%',
      zoom: {
        enabled: false,
      },
    },
    colors: ['#00E396'],
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    xaxis: {
      categories: powerSupplyChartData.categories,
      title: {
        text: 'Month',
        style: {
          color: '#FFFFFF',
        },
      },
      labels: {
        style: {
          colors: '#FFFFFF', // Ensure x-axis labels are white
        },
      },
    },
    yaxis: {
      title: {
        text: 'Number of Failures',
        style: {
          color: '#FFFFFF',
        },
      },
      min: 0,
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      floating: true,
      offsetY: 0,
      labels: {
        colors: '#FFFFFF',
      },
    },
    // Add padding to the top of the chart
    grid: {
      padding: {
        top: 20, // Increased padding to move the chart higher
      },
    },
  };

  return (
    <div className="bg-gray-800 rounded-xl p-4 h-full mb-4">
      <h2 className="text-xl font-extrabold mb-4 flex items-center text-white">
        <FaPlug className="mr-2 text-blue-300" /> Power Supply Failures
      </h2>
      <div className="h-56"> {/* Adjusted height for better fit */}
        <ReactApexChart options={powerSupplyOptions} series={powerSupplyChartData.series} type="line" height="100%" />
      </div>
    </div>
  );
};

export default PowerSupplyFailures;
