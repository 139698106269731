import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCheckCircle, faExclamationCircle, faMapMarkerAlt, faTags } from "@fortawesome/free-solid-svg-icons";

const FilteredAgentsTable = ({ filteredAgents }) => {
  return (
    <div className="mt-6">
      <h3 className="text-white text-lg mb-2">Filtered Agents</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-700 text-white rounded-lg overflow-hidden">
          <thead>
            <tr className="bg-gray-600">
              <th className="py-2 px-4">Name</th>
              <th className="py-2 px-4">Availability</th>
              <th className="py-2 px-4">Area</th>
              <th className="py-2 px-4">Category</th>
            </tr>
          </thead>
          <tbody>
            {filteredAgents.slice(0, 10).map(agent => (
              <tr key={agent.id} className="border-b border-gray-500 hover:bg-gray-600 transition-colors duration-300">
                <td className="py-2 px-4">
                  <FontAwesomeIcon icon={faUser} className="mr-2" />
                  {agent.name}
                </td>
                <td className="py-2 px-4">
                  <FontAwesomeIcon 
                    icon={agent.availability === "available" ? faCheckCircle : faExclamationCircle} 
                    className={`mr-2 ${agent.availability === "available" ? 'text-green-500' : 'text-red-500'}`} 
                  />
                  {agent.availability}
                </td>
                <td className="py-2 px-4">
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                  {agent.area}
                </td>
                <td className="py-2 px-4">
                  <FontAwesomeIcon icon={faTags} className="mr-2" />
                  {agent.category}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FilteredAgentsTable;
