import React from 'react';

const V2KPI = ({ icon, value, label, valueColor }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '15px',
        padding: '10px',
        backgroundColor: 'transparent', // Set to transparent
        borderRadius: '8px',
        width: '250px',
        gap: '10px',
      }}
    >
      <div style={{ flex: 1, textAlign: 'right' }}>
        <div
          style={{
            fontSize: '0.6rem', // Smaller label font size
            color: 'white', // Text color
            fontWeight: '500',
            marginBottom: '3px',
            fontFamily: 'Arial, sans-serif', // Modern font
          }}
        >
          {label}
        </div>
        <div
          style={{
            fontSize: '1.2rem', // Value font size
            fontWeight: 'bold',
            color: valueColor || 'white', // Text color
            fontFamily: 'Arial, sans-serif', // Modern font
          }}
        >
          {value}
        </div>
      </div>

      <i
        className={icon}
        style={{
          fontSize: '1.5rem',
          color: '#fff', // White icon color
          backgroundColor: 'rgba(0, 0, 0, 0.3)', // Low opacity black background
          borderRadius: '8px', // Rectangular shape with slight rounding
          padding: '10px 15px', // Padding for the rectangle
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Soft shadow for a modern look
        }}
      ></i>
    </div>
  );
};

export default V2KPI;
