import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const ViolationTimePeriodsChartParis = ({ darkMode }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    // Simulated data for violations by time periods
    const simulatedData = {
      Morning: 150,
      Afternoon: 250,
      Evening: 300,
      Night: 200,
    };

    setChartData(simulatedData);
  }, []);

  const categories = Object.keys(chartData);
  const series = [{
    name: 'Violations',
    data: categories.map(category => chartData[category]),
  }];

  const options = {
    chart: {
      type: 'bar',
      height: 300,
      background: 'transparent',
      toolbar: { show: false },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
      },
    },
    colors: ['#48BB78'], // Consistent color with other charts
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        borderRadius: 8,
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          colors: darkMode ? '#fff' : '#000',
          fontSize: '12px',
          fontWeight: 'medium',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Number of Violations',
        style: {
          color: darkMode ? '#fff' : '#000',
        },
      },
      labels: {
        style: {
          color: darkMode ? '#fff' : '#000',
        },
      },
    },
    grid: {
      borderColor: darkMode ? '#444' : '#ddd',
    },
  };

  return (
    <div className={`p-3 rounded-lg ${darkMode ? 'bg-gray-800/80 text-gray-200' : 'bg-white/30 text-gray-900'} relative`} style={{ width: '600px' }}>
         <div className="text-sm font-semibold mb-1 text-left" style={{ textShadow: '1px 1px 2px rgba(0,0,0,0.5)', marginBottom: '5px' }}>
        Violation Trends for Paris by Time Period
      </div>
      <Chart options={options} series={series} type="bar" height="185" />
    </div>
  );
};

export default ViolationTimePeriodsChartParis;
