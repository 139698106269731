import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faClipboardList, faRunning, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { Bar, Line } from 'react-chartjs-2';

const PerformanceMetrics = () => {
  // State for metrics
  const [totalIssues, setTotalIssues] = useState(0);
  const [resolvedIssues, setResolvedIssues] = useState(0);
  const [inMissionIssues, setInMissionIssues] = useState(0);

  // Simulated trend data for the last 4 weeks
  const trendData = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
    datasets: [
      {
        label: 'Total Issues',
        data: [50, 60, 70, 50],
        fill: false,
        borderColor: 'rgba(255, 99, 132, 1)',
        tension: 0.1,
      },
      {
        label: 'Resolved Issues',
        data: [30, 40, 50, 30],
        fill: false,
        borderColor: 'rgba(54, 162, 235, 1)',
        tension: 0.1,
      },
      {
        label: 'In-Mission Issues',
        data: [20, 20, 30, 40],
        fill: false,
        borderColor: 'rgba(75, 192, 192, 1)',
        tension: 0.1,
      },
    ],
  };

  useEffect(() => {
    // Simulated data fetch
    const fetchData = async () => {
      const data = {
        total: 50,
        resolved: 30,
        inMission: 20,
      };
      setTotalIssues(data.total);
      setResolvedIssues(data.resolved);
      setInMissionIssues(data.inMission);
    };
    fetchData();
  }, []);

  // Chart options
  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        labels: {
          color: 'white',
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: 'white',
        },
      },
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: 'white',
        },
      },
    },
  };

  return (
    <div className="bg-gray-800 rounded-lg shadow-lg mt-6 p-4">
      <h2 className="text-xl text-white font-bold mb-4 flex items-center">
        <FontAwesomeIcon icon={faChartBar} className="mr-2" />
        Performance Metrics Overview
      </h2>

      {/* KPI Metrics Section */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
        {[
          { icon: faClipboardList, label: 'Total Issues', value: totalIssues, color: 'blue-900' },
          { icon: faClipboardCheck, label: 'Resolved Issues', value: resolvedIssues, color: 'green-900' },
          { icon: faRunning, label: 'In-Mission Issues', value: inMissionIssues, color: 'orange-900' },
        ].map((metric, index) => (
          <div key={index} className={`flex flex-col items-center bg-${metric.color} p-4 rounded-lg shadow-md transition-transform duration-300 hover:scale-105`}>
            <FontAwesomeIcon icon={metric.icon} className="text-3xl text-yellow-400 mb-2" />
            <h3 className="text-lg text-white">{metric.label}</h3>
            <p className="text-3xl text-white font-bold">{metric.value}</p>
          </div>
        ))}
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
        <div className="bg-gray-800 p-4 rounded-lg shadow-md" style={{ height: '200px' }}>
          <h3 className="text-lg text-white mb-2">Issues Trend Over Time</h3>
          <Line data={trendData} options={chartOptions} />
        </div>
        <div className="bg-gray-800 p-4 rounded-lg shadow-md" style={{ height: '200px' }}>
          <h3 className="text-lg text-white mb-2">Incident Breakdown</h3>
          <Bar
            data={{
              labels: ['Critical', 'Major', 'Minor'],
              datasets: [
                {
                  label: 'Incident Types',
                  data: [5, 15, 30], // Example data
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.9)', // Critical
                    'rgba(54, 162, 235, 0.9)', // Major
                    'rgba(75, 192, 192, 0.9)', // Minor
                  ],
                  borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(75, 192, 192, 1)',
                  ],
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              ...chartOptions,
              scales: {
                ...chartOptions.scales,
                y: {
                  ...chartOptions.scales.y,
                  ticks: {
                    ...chartOptions.scales.y.ticks,
                    color: 'white',
                  },
                },
                x: {
                  ...chartOptions.scales.x,
                  ticks: {
                    ...chartOptions.scales.x.ticks,
                    color: 'white',
                  },
                },
              },
            }}
          />
        </div>
      </div>

      {/* Recent Incident Table Section */}
      <h3 className="text-lg text-white mb-2">Recent Incidents</h3>
      <table className="min-w-full bg-gray-700 text-white rounded-lg overflow-hidden mb-4">
        <thead>
          <tr className="bg-gray-600">
            <th className="py-2 px-4">Type</th>
            <th className="py-2 px-4">Description</th>
            <th className="py-2 px-4">Date</th>
          </tr>
        </thead>
        <tbody>
          {[
            { id: 1, type: 'Critical', description: 'System failure', date: '2024-10-20' },
            { id: 2, type: 'Major', description: 'Delayed response', date: '2024-10-18' },
            { id: 3, type: 'Minor', description: 'Minor bug', date: '2024-10-16' },
          ].map(incident => (
            <tr key={incident.id} className="border-b border-gray-500 hover:bg-gray-600 transition-colors duration-300">
              <td className="py-2 px-4">{incident.type}</td>
              <td className="py-2 px-4">{incident.description}</td>
              <td className="py-2 px-4">{incident.date}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Alert Message */}
      <div className="mt-4 text-white text-center">
        {inMissionIssues > 15 ? (
          <p className="font-semibold">🔔 Alert: Consider increasing agent availability!</p>
        ) : (
          <p className="font-semibold">👍 Good job! Keep maintaining low in-mission issues.</p>
        )}
      </div>
    </div>
  );
};

export default PerformanceMetrics;
