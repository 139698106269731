import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { FaHdd, FaExclamationTriangle, FaCloud } from 'react-icons/fa';

const StorageBackupAlertsCard = () => {
  // Fake data for Storage Capacity Warnings and Backup Failures
  const storageData = [
    { month: "Jan", capacity: 70 },
    { month: "Feb", capacity: 85 },
    { month: "Mar", capacity: 90 },
    { month: "Apr", capacity: 95 },
    { month: "May", capacity: 80 },
    { month: "Jun", capacity: 60 },
    { month: "Jul", capacity: 75 },
    { month: "Aug", capacity: 90 },
    { month: "Sep", capacity: 92 },
    { month: "Oct", capacity: 97 },
    { month: "Nov", capacity: 65 },
    { month: "Dec", capacity: 80 },
  ];

  const backupData = [
    { month: "Jan", failures: 1 },
    { month: "Feb", failures: 0 },
    { month: "Mar", failures: 2 },
    { month: "Apr", failures: 1 },
    { month: "May", failures: 3 },
    { month: "Jun", failures: 1 },
    { month: "Jul", failures: 0 },
    { month: "Aug", failures: 2 },
    { month: "Sep", failures: 0 },
    { month: "Oct", failures: 4 },
    { month: "Nov", failures: 1 },
    { month: "Dec", failures: 0 },
  ];

  // Chart options for storage capacity
  const storageOptions = {
    chart: {
      type: 'line',
      height: '100%',
      zoom: {
        enabled: false,
      },
    },
    colors: ['#FF4560'],
    stroke: {
      width: 3,
      curve: 'smooth',
    },
    xaxis: {
      categories: storageData.map(item => item.month),
      title: {
        text: 'Month',
        style: {
          color: '#FFFFFF',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Storage Capacity (%)',
        style: {
          color: '#FFFFFF',
        },
      },
      min: 0,
      max: 100,
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
  };

  // Chart options for backup failures
  const backupOptions = {
    chart: {
      type: 'bar',
      height: '100%',
      zoom: {
        enabled: false,
      },
    },
    colors: ['#00E396'],
    xaxis: {
      categories: backupData.map(item => item.month),
      title: {
        text: 'Month',
        style: {
          color: '#FFFFFF',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Backup Failures',
        style: {
          color: '#FFFFFF',
        },
      },
      min: 0,
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
  };

  return (
    <div className="bg-gray-800 rounded-xl p-4 h-64 flex flex-col text-white">
      <h2 className="text-xl font-extrabold mb-4 flex items-center">
        <FaHdd className="mr-2 text-blue-300" /> Storage & Backup Alerts
      </h2>

      <div className="flex justify-between h-full">
        <div className="flex flex-col w-1/2">
          <h3 className="text-lg font-bold mb-2">
            Storage Capacity Warnings
          
          </h3>
          <div className="flex-grow">
            <ReactApexChart
              options={storageOptions}
              series={[{ name: 'Capacity', data: storageData.map(item => item.capacity) }]}
              type="line"
              height="100%"
            />
          </div>
        </div>
        
        <div className="flex flex-col w-1/2">
          <h3 className="text-lg font-bold mb-2">
            Backup Failures
            <FaCloud className="inline-block text-blue-300 ml-2" />
          </h3>
          <div className="flex-grow">
            <ReactApexChart
              options={backupOptions}
              series={[{ name: 'Failures', data: backupData.map(item => item.failures) }]}
              type="bar"
              height="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorageBackupAlertsCard;
